import axios from "axios";
import {
  PROFILE_FETCH,
  SEARCH_FETCH,
  PROFILE_EDIT,
} from "../../../config/api-const";

export const fetchProfile = async (token) =>
  token && (await axios.get(PROFILE_FETCH + token));

export const saveProfile = async (token, profile) => {
  const profileObj = profile;

  try {
    return await axios.post(PROFILE_EDIT, {
      profileObj,
      token,
    });
  } catch (err) {
    console.error("Opps, Error in Updating. Try after sometime later \n", err);
  }
};

export const fetchAllProfile = async (token) => {
  try {
    return await axios.get(SEARCH_FETCH + token);
  } catch (err) {
    console.error(
      "Opps, Error in fetchAllProfile. Try after sometime later \n",
      err
    );
  }
};
