import { errorToaster, successToaster } from "../utils/alerts";
import { saveProfile } from "../view/profile/store/services";

export const handleSaveProfile = (token, profileObj) => {
  saveProfile(token, profileObj)
    .then(
      (res) =>
        res.data.success &&
        successToaster("Profile Updated Successfully!!!", 4000)
    )
    .catch(
      (err) =>
        err &&
        errorToaster(
          "Facing issue in saving profile. Please Try After Sometime!!!",
          4000
        )
    );
};
