import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorToaster, successToaster } from "../../utils/alerts";
import { getItem, setItem } from "../../utils/local-storage";
import { getUser } from "../../store/selector";
import { onSignIn, verifyToken } from "../../store/services";
import { actions } from "../../store/slice";
import LayoutPage from "../layout-page";
import TextInput from "../text-input";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const Login = () => {
  const currentUser = useSelector(getUser);
  const { emailAddress, password } = currentUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const token = getItem("the_main_app");
    token &&
      verifyToken(token)
        .then((res) => {
          if (res.data.success) {
            dispatch(actions.setToken(token));
            dispatch(actions.setIsHeaderFooter(true));
            navigate("/");
          }
        })
        .catch((err) => console.error("err", err));
  });

  const loginInputs = [
    {
      type: "email",
      placeholder: "Enter email",
      value: emailAddress,
      name: "emailAddress",
      onChange: (e) => {
        e.preventDefault();
        dispatch(
          actions.setCurrentUser({
            ...currentUser,
            emailAddress: e.target.value,
          })
        );
      },
    },
    {
      type: "password",
      placeholder: "Password",
      name: "password",
      value: password,
      onChange: (e) => {
        e.preventDefault();
        dispatch(
          actions.setCurrentUser({
            ...currentUser,
            password: e.target.value,
          })
        );
      },
      onKeyDown: (e) => {
        if (e.key === "Enter") {
          handleLogin(e);
        }
      },
    },
  ];

  const handleLogin = (e) => {
    e.preventDefault();
    onSignIn({ emailAddress, password })
      .then((res) => {
        if (res.data.success) {
          setItem("the_main_app", res.data.token);
          setItem("current_user", res.data.currentUser);
          successToaster(res.data.message, 4000);
          dispatch(actions.setToken(res.data.token));
          dispatch(
            actions.setLoginSuccess({
              emailAddress: res.data.currentUser.emailAddress,
              userId: res.data.currentUser.userId,
              username: res.data.currentUser.username,
            })
          );
        } else {
          errorToaster(res.data.message, 4000);
          dispatch(
            actions.setError({
              status: true,
              message: res.data.message,
              severity: "error",
            })
          );
          console.log("success false");
        }
      })
      .catch((err) => console.error("err", err));
  };

  return (
    <LayoutPage
      content={
        <div>
          {loginInputs.map(
            (
              { type, placeholder, name, value, onChange, onKeyDown },
              index
            ) => (
              <TextInput
                key={index}
                type={type}
                className="input-field"
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
              />
            )
          )}

          {/* <div className="custom-control custom-checkbox form-group">
            <input
              type="checkbox"
              className="custom-control-input"
              id="exampleCheck1"
            />
            <label className="custom-control-label" htmlFor="exampleCheck1">
              Remember
            </label>
          </div> */}
          {/* <a href="#" className="float-left forgot-link">
            Forgot password?
          </a> */}
          <button
            className="btn btn-primary float-right m-l-xxs"
            style={{ marginRight: "6px" }}
            onClick={(e) => handleLogin(e)}
          >
            Sign In
          </button>
          <button
            onClick={() => navigate("/register")}
            className="btn btn-secondary float-right"
          >
            Register
          </button>
        </div>
      }
      title="Login"
    />
  );
};

export default Login;
