import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getItem } from "../../../utils/local-storage";
import PaginationComponent from "../../../components/pagination";
import ProfileCard from "../../../components/profile-card";
import useMediaQuery from "../../../hooks/use-media-query";
import { fetchAllProfile } from "../../profile/store/services";
import { getPagination, getProfiles } from "../store/selector";
import { actions } from "../store/slice";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const ProfilesPage = () => {
  const profiles = useSelector(getProfiles);
  const { pageSize, pageNumber } = useSelector(getPagination);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDesktop } = useMediaQuery();
  const location = useLocation();

  const paginate = (items, page = 1, perPage = 2) => {
    return items.slice(perPage * (page - 1), perPage * page);
  };

  useEffect(() => {
    !getItem("the_main_app") && navigate("/login");
  });

  useEffect(() => {
    fetchAllProfile(getItem("the_main_app"))
      .then((response) => {
        dispatch(actions.setAllProfiles(response.data.values));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  const handlePagination = (event, value) => {
    dispatch(actions.setPageNumber(value));
  };

  const onClickReadMore = (props) => {
    navigate(`/members/${props.userId}`);
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div style={{ marginTop: isDesktop ? "72px" : "96px" }}>
      {profiles.length > 0 ? (
        <Grid
          container
          style={{ padding: "50px", display: "flex", justifyContent: "center" }}
        >
          {paginate(profiles, pageNumber, pageSize).map((props, index) => (
            <Grid
              id="profile-card-grid"
              item
              key={index}
              style={{ padding: "10px" }}
            >
              <ProfileCard
                {...props}
                index={index}
                onClickReadMore={() => onClickReadMore(props)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          style={{ padding: "50px", display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12} className="gridItem">
            <h1
              className="font-weight-semibold banner-content-text mb-1"
              style={{
                // textTransform: "uppercase",
                fontFamily: "Righteous",
                fontSize: "48px",
              }}
            >
              No Profiles Available for Preview
            </h1>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid
          id="pagination-grid"
          className="gridItem"
          item
          xs={12}
          style={{ padding: "25px" }}
        >
          <PaginationComponent
            onChange={handlePagination}
            pageSize={pageSize}
            recordSize={profiles.length}
            pageNumber={pageNumber}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfilesPage;
