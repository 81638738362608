export const languages = [
  { code: "en", name: "English" },
  {
    name: "Assamese",
    Family: "Indo-Aryan",
    code: "as",
  },
  {
    name: "Bengali (Bangla)",
    Family: "Indo-Aryan",
    code: "bn",
  },
  {
    name: "Bodo",
    Family: "Sino-Tibetan",
    code: "brx",
  },
  {
    name: "Dogri",
    Family: "Indo-Aryan",
    code: "doi",
  },
  {
    name: "Gujarati",
    Family: "Indo-Aryan",
    code: "gu",
  },
  {
    name: "Hindi",
    Family: "Indo-Aryan",
    code: "hi",
  },
  {
    name: "Kannada",
    Family: "Dravidian",
    code: "kn",
  },
  {
    name: "Kashmiri",
    Family: "Indo-Aryan",
    code: "ks",
  },
  {
    name: "Konkani",
    Family: "Indo-Aryan",
    code: "gom",
  },
  {
    name: "Maithili",
    Family: "Indo-Aryan",
    code: "mai",
  },
  {
    name: "Malayalam",
    Family: "Dravidian",
    code: "ml",
  },
  {
    name: "Meitei (Manipuri)",
    Family: "Sino-Tibetan",
    code: "mni",
  },
  {
    name: "Marathi",
    Family: "Indo-Aryan",
    code: "mr",
  },
  {
    name: "Nepali",
    Family: "Indo-Aryan",
    code: "ne",
  },
  {
    name: "Odia",
    Family: "Indo-Aryan",
    code: "or",
  },
  {
    name: "Punjabi",
    Family: "Indo-Aryan",
    code: "pa",
  },
  {
    name: "Sanskrit",
    Family: "Indo-Aryan",
    code: "sa",
  },
  {
    name: "Santali",
    Family: "Austroasiatic",
    code: "sat",
  },
  {
    name: "Sindhi",
    Family: "Indo-Aryan",
    code: "sd",
  },
  {
    name: "Tamil",
    Family: "Dravidian",
    code: "ta",
  },
  {
    name: "Telugu",
    Family: "Dravidian",
    code: "te",
  },
  {
    name: "Urdu",
    Family: "Indo-Aryan",
    code: "ur",
  },
];

export const twelveNumbers = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const officeContactDetails = [
  {
    title: "Location 1 - Head Office",
    emailId: "info@kalyanawaran.com",
    phone: "+919842393234",
    addressFirstLine: "515, Sallivan Street",
    addressSecondLine: "",
    city: "Coimbatore",
    pinCode: "641 001",
  },
];

export const socialLinks = [];

export const Links = {
  terms: "/terms",
  privacy: "/privacy-policy",
  cookie: "/cookie-policy",
};

export const translations = {
  en: {
    birthDate: "Birth Date",
    birthPlace: "Birth Place",
    birthTime: "Birth Time",
    naligai: "Naligai",
    birthStar: "Birth Star",
    birthRasi: "Birth Rasi",
    thithi: "Thithi",
    lagnam: "Lagnam",
    day: "Day",
    tamilYear: "Tamil Year",
    tamilMonth: "Tamil Month",
    tamilDate: "Tamil Date",
  },
  ta: {
    birthDate: "ஆங்கில தேதி",
    tamilYear: "தமிழ் வருடம்",
    tamilMonth: "தமிழ் மாதம்",
    birthPlace: "பிறந்த ஊர்",
    birthTime: "பிறந்த நேரம்",
    naligai: "நாழிகை",
    birthStar: "நட்சித்திரம்",
    birthRasi: "ராசி",
    thithi: "திதி",
    lagnam: "லக்கினம்",
    day: "நாள்",
    tamilDate: "தமிழ் தேதி",
  },
};

export const days = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
  { value: 15 },
  { value: 16 },
  { value: 17 },
  { value: 18 },
  { value: 19 },
  { value: 20 },
  { value: 21 },
  { value: 22 },
  { value: 23 },
  { value: 24 },
  { value: 25 },
  { value: 26 },
  { value: 27 },
  { value: 28 },
  { value: 29 },
  { value: 30 },
  { value: 31 },
];

export const tamilMonth = {
  en: [
    { value: "Chittirai" },
    { value: "Vaikaasi" },
    { value: "Aani" },
    { value: "Aadi" },
    { value: "Aavani" },
    { value: "Purattaasi" },
    { value: "Aippasi" },
    { value: "Kaartikai" },
    { value: "Maargali" },
    { value: "Tai" },
    { value: "Maagha" },
    { value: "Panguni" },
  ],
  ta: [
    { value: "சித்திரை" },
    { value: "வைகாசி" },
    { value: "ஆனி" },
    { value: "ஆடி" },
    { value: "ஆவணி" },
    { value: "புரட்டாசி" },
    { value: "ஐப்பசி" },
    { value: "கார்த்திகை" },
    { value: "மார்கழி" },
    { value: "தை" },
    { value: "மாசி" },
    { value: "பங்குனி" },
  ],
};

export const getTranslation = (lang, text) => {
  return translations[lang][text];
};

export const iAm = [{ value: "Groom" }, { value: "Bride" }];
export const lookingFor = [{ nameValue: "Groom" }, { nameValue: "Bride" }];
export const gender = [{ genderValue: "Male" }, { genderValue: "Female" }];
export const yesOrNo = [{ value: "Yes" }, { value: "No" }];
export const dayOrder = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
export const weekday = {
  en: {
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
  },
  ta: {
    sunday: "ஞாயிற்றுக்கிழமை",
    monday: "திங்கட்கிழமை",
    tuesday: "செவ்வாய்க்கிழமை",
    wednesday: "புதன்கிழமை",
    thursday: "வியாழக்கிழமை",
    friday: "வெள்ளிக்கிழமை",
    saturday: "சனிக்கிழமை",
  },
};

export const tamilYear = {
  en: [
    { value: "Prabhāva" },
    { value: "Vibhāva" },
    { value: "Śuklā" },
    { value: "Pramadutā" },
    { value: "Prachopati" },
    { value: "Āṅgirasa" },
    { value: "Śrīmukha" },
    { value: "Bhava" },
    { value: "Yuva" },
    { value: "Dhātu" },
    { value: "Īśvara" },
    { value: "Vehudānya" },
    { value: "Pramāti" },
    { value: "Vikrama" },
    { value: "Viṣu" },
    { value: "Citrabhānu" },
    { value: "Subhānu" },
    { value: "Dhārana" },
    { value: "Partibhā" },
    { value: "Viya" },
    { value: "Sarvajit" },
    { value: "Sarvadhārī" },
    { value: "Virodhī" },
    { value: "Vikṛti" },
    { value: "Kara" },
    { value: "Nandhana" },
    { value: "Vijaya" },
    { value: "Jaya" },
    { value: "Manmatha" },
    { value: "Dhuṇmūkī" },
    { value: "Hēvilaṃbi" },
    { value: "Vilaṃbi" },
    { value: "Vikāri" },
    { value: "Śarvarī" },
    { value: "Plava" },
    { value: "Śubhakṛt" },
    { value: "Śobhakṛt" },
    { value: "Krodhī" },
    { value: "Viśvāvasuva" },
    { value: "Parapāva" },
    { value: "Plavaṅga" },
    { value: "Kīlaka" },
    { value: "Saumya" },
    { value: "Sādhāraṇa" },
    { value: "Virodhikṛti" },
    { value: "Paritapi" },
    { value: "Pramādīca" },
    { value: "Ānanda" },
    { value: "Rākṣasaḥ" },
    { value: "Nala" },
    { value: "Piṅgāla" },
    { value: "Kālayukti" },
    { value: "Siddhidātrī" },
    { value: "Rautrī" },
    { value: "Dhūnmatī" },
    { value: "Dundubhi" },
    { value: "Rudhirōtgāri" },
    { value: "Rākṣasī" },
    { value: "Krodhanā" },
    { value: "Akṣayā" },
  ],
  ta: [
    { value: "பிரபவ" },
    { value: "விபவ" },
    { value: "சுக்ல" },
    { value: "பிரமோதூத" },
    { value: "பிரசோற்பத்தி" },
    { value: "ஆங்கீரச" },
    { value: "ஸ்ரீமுக" },
    { value: "பவ" },
    { value: "யுவ" },
    { value: "தாது" },
    { value: "ஈஸ்வர" },
    { value: "வெகுதானிய" },
    { value: "பிரமாதி" },
    { value: "விக்ரம" },
    { value: "விஷு" },
    { value: "சித்திரபானு" },
    { value: "சுபானு" },
    { value: "தாரண" },
    { value: "பார்த்திப" },
    { value: "விய" },
    { value: "சர்வஜித்" },
    { value: "சர்வதாரி" },
    { value: "விரோதி" },
    { value: "விக்ருதி" },
    { value: "கர" },
    { value: "நந்தன" },
    { value: "விஜய" },
    { value: "ஜய" },
    { value: "மன்மத" },
    { value: "துன்முகி" },
    { value: "ஹேவிளம்பி" },
    { value: "விளம்பி" },
    { value: "விகாரி" },
    { value: "சார்வரி" },
    { value: "பிலவ" },
    { value: "சுபகிருது" },
    { value: "சோபக்ருத்" },
    { value: "க்ரோதி" },
    { value: "விசுவாசுவ" },
    { value: "பரபாவ" },
    { value: "ப்லவங்க" },
    { value: "கீலக" },
    { value: "சௌம்ய" },
    { value: "சாதாரண" },
    { value: "விரோதகிருது" },
    { value: "பரிதாபி" },
    { value: "பிரமாதீச" },
    { value: "ஆனந்த" },
    { value: "ராட்சச" },
    { value: "நள" },
    { value: "பிங்கள" },
    { value: "காளயுக்தி" },
    { value: "சித்தார்த்தி" },
    { value: "ரௌத்திரி" },
    { value: "துன்மதி" },
    { value: "துந்துபி" },
    { value: "ருத்ரோத்காரி" },
    { value: "ரக்தாட்சி" },
    { value: "க்ரோதன" },
    { value: "அட்சய" },
  ],
};

export const siblings = [
  { siblingsValue: "Brother" },
  { siblingsValue: "Sister" },
];

export const maritalStatus = [
  { value: "Single" },
  { value: "Married" },
  { value: "Others" },
];

export const religion = [
  { religionValue: "Religion - Any" },
  { religionValue: "Hindu" },
  { religionValue: "Muslim - All" },
  { religionValue: "Muslim - Shia" },
  { religionValue: "Muslim - Sunni" },
  { religionValue: "Muslim - Others" },
  { religionValue: "Christian" },
  { religionValue: "Sikh" },
  { religionValue: "Jain - All" },
  { religionValue: "Jain - Digambar" },
  { religionValue: "Jain - Shwetambar" },
  { religionValue: "Jain - Others" },
  { religionValue: "Parsi" },
  { religionValue: "Buddhist" },
  { religionValue: "Inter-Religion" },
];

export const createdBy = [
  { createdByValue: "Self" },
  { createdByValue: "Parent" },
  { createdByValue: "Others" },
];

export const rasi = [
  { rasiValue: "Mesham" },
  { rasiValue: "Rishabam" },
  { rasiValue: "Midhunam" },
  { rasiValue: "Kadagam" },
  { rasiValue: "Simam" },
  { rasiValue: "Kanni" },
  { rasiValue: "Tulam" },
  { rasiValue: "Viruchagam" },
  { rasiValue: "Dhanusu" },
  { rasiValue: "Magaram" },
  { rasiValue: "Kumbam" },
  { rasiValue: "Meenam" },
];

export const height = [
  { value: "4.5" },
  { value: "4.6" },
  { value: "4.7" },
  { value: "4.8" },
  { value: "4.9" },
  { value: "4.10" },
  { value: "4.11" },
  { value: "5" },
  { value: "5.1" },
  { value: "5.2" },
  { value: "5.3" },
  { value: "5.4" },
  { value: "5.5" },
  { value: "5.6" },
  { value: "5.7" },
  { value: "5.8" },
  { value: "5.9" },
  { value: "5.10" },
  { value: "5.11" },
  { value: "6" },
  { value: "6.1" },
  { value: "6.2" },
  { value: "6.3" },
  { value: "6.4" },
  { value: "6.5" },
  { value: "6.6" },
  { value: "6.7" },
  { value: "6.8" },
  { value: "6.9" },
  { value: "6.10" },
  { value: "6.11" },
  { value: "7" },
];

export const fieldOfStudy = [
  { fieldOfStudyValue: "Architecture" },
  { fieldOfStudyValue: "Business" },
  { fieldOfStudyValue: "Commerce" },
  { fieldOfStudyValue: "Design" },
  { fieldOfStudyValue: "Engineering" },
  { fieldOfStudyValue: "Fashion" },
  { fieldOfStudyValue: "Finance" },
  { fieldOfStudyValue: "Hotel" },
  { fieldOfStudyValue: "Humanities &amp; Arts" },
  { fieldOfStudyValue: "Law" },
  { fieldOfStudyValue: "Management" },
  { fieldOfStudyValue: "Medicine" },
  { fieldOfStudyValue: "Psychology" },
  { fieldOfStudyValue: "Science" },
  { fieldOfStudyValue: "Sports" },
  { fieldOfStudyValue: "Other" },
];

export const nakshatra = [
  { nakshatraValue: "Ashwini" },
  { nakshatraValue: "Bharani" },
  { nakshatraValue: "Krittika" },
  { nakshatraValue: "Rohini" },
  { nakshatraValue: "Mrigashirsha" },
  { nakshatraValue: "Ardra/Thiruvathirai" },
  { nakshatraValue: "Punarvasu" },
  { nakshatraValue: "Pushya" },
  { nakshatraValue: "Ashlesha/Ayilum" },
  { nakshatraValue: "Magha" },
  { nakshatraValue: "Purva Phalguni/Pooram" },
  { nakshatraValue: "Uttara Phalguni/Uthram" },
  { nakshatraValue: "Hasta" },
  { nakshatraValue: "Chitra" },
  { nakshatraValue: "Swati" },
  { nakshatraValue: "Vishakha" },
  { nakshatraValue: "Anuradha/Anusham" },
  { nakshatraValue: "Jyeshta/Kaetai" },
  { nakshatraValue: "Mula" },
  { nakshatraValue: "Purvashadha/Pooradam" },
  { nakshatraValue: "Uttarashadha/Uttradam" },
  { nakshatraValue: "Shravana/Thiruvonam" },
  { nakshatraValue: "Dhanistha/Avitam" },
  { nakshatraValue: "Shatabhishak/Sadhayam" },
  { nakshatraValue: "Purva Bhadrapada/Pooratadhi" },
  { nakshatraValue: "Uttara Bhadrapada/Uttratadhi" },
  { nakshatraValue: "Revati" },
];

export const bloodGroup = [
  { value: "A positive (A+)" },
  { value: "A negative (A-)" },
  { value: "B positive (B+)" },
  { value: "B negative (B-)" },
  { value: "O positive (O+)" },
  { value: "O negative (O-)" },
  { value: "AB positive (AB+)" },
  { value: "AB negative (AB-)" },
];

export const caste = [
  { casteName: "Caste / Division - Any" },
  { casteName: "24 Manai Telugu Chettiar" },
  {
    casteName: "96 Kuli Maratha",
  },
  {
    casteName: "Aaru Nattu Vellala",
  },
  {
    casteName: "Achirapakkam Chettiar",
  },
  {
    casteName: "Ad Dharmi",
  },
  {
    casteName: "Adi Andhra",
  },
  {
    casteName: "Adi Dravidar",
  },
  {
    casteName: "Adi Karnataka",
  },
  {
    casteName: "Adventist",
  },
  {
    casteName: "Agamudayar / Arcot / Thuluva Vellala",
  },
  {
    casteName: "Agaram Vellan Chettiar",
  },
  {
    casteName: "Agarwal",
  },
  {
    casteName: "Agnikula Kshatriya",
  },
  {
    casteName: "Agrahari",
  },
  {
    casteName: "Agri",
  },
  {
    casteName: "Aguri / Ugra Kshatriya",
  },
  {
    casteName: "Aheriya",
  },
  {
    casteName: "Ahir Shimpi",
  },
  {
    casteName: "Ahirwar",
  },
  {
    casteName: "Ahom",
  },
  {
    casteName: "Ambalavasi",
  },
  {
    casteName: "Amil Sindhi",
  },
  {
    casteName: "Amma Kodava",
  },
  {
    casteName: "Anavil Brahmin",
  },
  {
    casteName: "Anglican / Episcopal",
  },
  {
    casteName: "Anglo-Indian",
  },
  {
    casteName: "Anjana (Chowdary) Patel",
  },
  {
    casteName: "Apostolic",
  },
  {
    casteName: "Arakh / Arakvanshiya",
  },
  {
    casteName: "Aramari / Gabit",
  },
  {
    casteName: "Arekatica",
  },
  {
    casteName: "Arora",
  },
  {
    casteName: "Arunthathiyar",
  },
  {
    casteName: "Arya Vysya",
  },
  {
    casteName: "Asathi",
  },
  {
    casteName: "Assembly of God (AG)",
  },
  {
    casteName: "Audichya Brahmin",
  },
  {
    casteName: "Ayira Vysya",
  },
  {
    casteName: "Ayodhyavasi",
  },
  {
    casteName: "Ayyaraka",
  },
  {
    casteName: "Badaga",
  },
  {
    casteName: "Bagdi",
  },
  {
    casteName: "Baibhand Sindhi",
  },
  {
    casteName: "Baidya",
  },
  {
    casteName: "Bairwa",
  },
  {
    casteName: "Baishnab",
  },
  {
    casteName: "Baishya",
  },
  {
    casteName: "Bajantri",
  },
  {
    casteName: "Balai",
  },
  {
    casteName: "Balija",
  },
  {
    casteName: "Balija Naidu",
  },
  {
    casteName: "Balija Reddy",
  },
  {
    casteName: "Banayat Oriya",
  },
  {
    casteName: "Banik",
  },
  {
    casteName: "Baniya",
  },
  {
    casteName: "Baniya - Bania",
  },
  {
    casteName: "Baniya - Kumuti",
  },
  {
    casteName: "Banjara",
  },
  {
    casteName: "Baptist",
  },
  {
    casteName: "Barai",
  },
  {
    casteName: "Barendra Brahmin",
  },
  {
    casteName: "Bari",
  },
  {
    casteName: "Baria",
  },
  {
    casteName: "Barnwal",
  },
  {
    casteName: "Barujibi",
  },
  {
    casteName: "Beri Chettiar",
  },
  {
    casteName: "Besta",
  },
  {
    casteName: "Bhandari",
  },
  {
    casteName: "Bhanusali Sindhi",
  },
  {
    casteName: "Bhatia",
  },
  {
    casteName: "Bhatia Sindhi",
  },
  {
    casteName: "Bhatraju",
  },
  {
    casteName: "Bhatt Brahmin",
  },
  {
    casteName: "Bhavasar Kshatriya",
  },
  {
    casteName: "Bhil",
  },
  {
    casteName: "Bhoi",
  },
  {
    casteName: "Bhovi",
  },
  {
    casteName: "Bhoyar",
  },
  {
    casteName: "Bhulia / Meher",
  },
  {
    casteName: "Bhumihar Brahmin",
  },
  {
    casteName: "Billava",
  },
  {
    casteName: "Bishnoi/Vishnoi",
  },
  {
    casteName: "Bondili",
  },
  {
    casteName: "Born Again",
  },
  {
    casteName: "Boyar",
  },
  {
    casteName: "Brahmakshtriya",
  },
  {
    casteName: "Brahmbatt",
  },
  {
    casteName: "Brahmin - All",
  },
  {
    casteName: "Brahmin - Anavil",
  },
  {
    casteName: "Brahmin - Anaviln Desai",
  },
  {
    casteName: "Brahmin - Audichya",
  },
  {
    casteName: "Brahmin - Baidhiki/Vaidhiki",
  },
  {
    casteName: "Brahmin - Bardai",
  },
  {
    casteName: "Brahmin - Barendra",
  },
  {
    casteName: "Brahmin - Bhargav",
  },
  {
    casteName: "Brahmin - Bhatt",
  },
  {
    casteName: "Brahmin - Bhumihar",
  },
  {
    casteName: "Brahmin - Dadhich",
  },
  {
    casteName: "Brahmin - Daivadnya",
  },
  {
    casteName: "Brahmin - Danua",
  },
  {
    casteName: "Brahmin - Deshastha",
  },
  {
    casteName: "Brahmin - Dhiman",
  },
  {
    casteName: "Brahmin - Dravida",
  },
  {
    casteName: "Brahmin - Embrandiri",
  },
  {
    casteName: "Brahmin - Garhwali",
  },
  {
    casteName: "Brahmin - Gaur",
  },
  {
    casteName: "Brahmin - Goswami/Gosavi",
  },
  {
    casteName: "Brahmin - Gujar Gaur",
  },
  {
    casteName: "Brahmin - Gurukkal",
  },
  {
    casteName: "Brahmin - Halua",
  },
  {
    casteName: "Brahmin - Havyaka",
  },
  {
    casteName: "Brahmin - Hoysala",
  },
  {
    casteName: "Brahmin - Iyengar",
  },
  {
    casteName: "Brahmin - Iyer",
  },
  {
    casteName: "Brahmin - Jangid",
  },
  {
    casteName: "Brahmin - Jhadua",
  },
  {
    casteName: "Brahmin - Jyotish",
  },
  {
    casteName: "Brahmin - Kanyakubj",
  },
  {
    casteName: "Brahmin - Karhade",
  },
  {
    casteName: "Brahmin - Khadayata",
  },
  {
    casteName: "Brahmin - Khandelwal",
  },
  {
    casteName: "Brahmin - Khedaval",
  },
  {
    casteName: "Brahmin - Kokanastha",
  },
  {
    casteName: "Brahmin - Kota",
  },
  {
    casteName: "Brahmin - Koteshwara / Kota (Madhwa )",
  },
  {
    casteName: "Brahmin - Kulin",
  },
  {
    casteName: "Brahmin - Kumaoni",
  },
  {
    casteName: "Brahmin - Madhwa",
  },
  {
    casteName: "Brahmin - Maithil",
  },
  {
    casteName: "Brahmin - Mevada",
  },
  {
    casteName: "Brahmin - Modh",
  },
  {
    casteName: "Brahmin - Mohyal",
  },
  {
    casteName: "Brahmin - Nagar",
  },
  {
    casteName: "Brahmin - Namboodiri",
  },
  {
    casteName: "Brahmin - Narmadiya",
  },
  {
    casteName: "Brahmin - Niyogi",
  },
  {
    casteName: "Brahmin - Others",
  },
  {
    casteName: "Brahmin - Paliwal",
  },
  {
    casteName: "Brahmin - Panda",
  },
  {
    casteName: "Brahmin - Pandit",
  },
  {
    casteName: "Brahmin - Panicker",
  },
  {
    casteName: "Brahmin - Pareek",
  },
  {
    casteName: "Brahmin - Pushkarna",
  },
  {
    casteName: "Brahmin - Rajgor",
  },
  {
    casteName: "Brahmin - Rarhi",
  },
  {
    casteName: "Brahmin - Rarhi/Radhi",
  },
  {
    casteName: "Brahmin - Rigvedi",
  },
  {
    casteName: "Brahmin - Rudraj",
  },
  {
    casteName: "Brahmin - Sakaldwipi",
  },
  {
    casteName: "Brahmin - Sanadya",
  },
  {
    casteName: "Brahmin - Sanketi",
  },
  {
    casteName: "Brahmin - Saraswat",
  },
  {
    casteName: "Brahmin - Sarua",
  },
  {
    casteName: "Brahmin - Saryuparin",
  },
  {
    casteName: "Brahmin - Shivalli (Madhva)",
  },
  {
    casteName: "Brahmin - Shivhalli",
  },
  {
    casteName: "Brahmin - Shri Gaud",
  },
  {
    casteName: "Brahmin - Shri Mali",
  },
  {
    casteName: "Brahmin - Shrimali",
  },
  {
    casteName: "Brahmin - Shukla Yajurvedi",
  },
  {
    casteName: "Brahmin - Sikhwal",
  },
  {
    casteName: "Brahmin - Smartha",
  },
  {
    casteName: "Brahmin - Sri Vaishnava",
  },
  {
    casteName: "Brahmin - Stanika",
  },
  {
    casteName: "Brahmin - Tapodhan",
  },
  {
    casteName: "Brahmin - Tyagi",
  },
  {
    casteName: "Brahmin - Vaidiki",
  },
  {
    casteName: "Brahmin - Vaikhanasa",
  },
  {
    casteName: "Brahmin - Valam",
  },
  {
    casteName: "Brahmin - Velanadu",
  },
  {
    casteName: "Brahmin - Vyas",
  },
  {
    casteName: "Brahmin - Zalora",
  },
  {
    casteName: "Brahmin Jijhotia",
  },
  {
    casteName: "Brahmin Malviya",
  },
  {
    casteName: "Brajastha Maithil",
  },
  {
    casteName: "Brajastha Maithil",
  },
  {
    casteName: "Brethren",
  },
  {
    casteName: "Bunt (Shetty)",
  },
  {
    casteName: "CKP",
  },
  {
    casteName: "Calvinist",
  },
  {
    casteName: "Chakkala Nair",
  },
  {
    casteName: "Chalawadi and Holeya",
  },
  {
    casteName: "Chaldean Syrian (Assyrian)",
  },
  {
    casteName: "Chambhar",
  },
  {
    casteName: "Chandravanshi Kahar",
  },
  {
    casteName: "Charan",
  },
  {
    casteName: "Chasa",
  },
  {
    casteName: "Chattada Sri Vaishnava",
  },
  {
    casteName: "Chaturth",
  },
  {
    casteName: "Chaudary",
  },
  {
    casteName: "Chaurasia",
  },
  {
    casteName: "Chennadasar",
  },
  {
    casteName: "Cherakula Vellalar",
  },
  {
    casteName: "Chettiar",
  },
  {
    casteName: "Chhapru Sindhi",
  },
  {
    casteName: "Chhetri",
  },
  {
    casteName: "Chippolu (Mera)",
  },
  {
    casteName: "Choudhary",
  },
  {
    casteName: "Christian - Others",
  },
  {
    casteName: "Church of Christ",
  },
  {
    casteName: "Church of God",
  },
  {
    casteName: "Church of North India",
  },
  {
    casteName: "Church of South India",
  },
  {
    casteName: "Congregational",
  },
  {
    casteName: "Coorgi",
  },
  {
    casteName: "Dadu Sindhi",
  },
  {
    casteName: "Daivadnya Brahmin",
  },
  {
    casteName: "Danua Brahmin",
  },
  {
    casteName: "Darji",
  },
  {
    casteName: "Dasapalanjika / Kannada Saineegar",
  },
  {
    casteName: "Deshastha Brahmin",
  },
  {
    casteName: "Deshmukh",
  },
  {
    casteName: "Desikar",
  },
  {
    casteName: "Desikar Thanjavur",
  },
  {
    casteName: "Devadiga",
  },
  {
    casteName: "Devandra Kula Vellalar",
  },
  {
    casteName: "Devang Koshthi",
  },
  {
    casteName: "Devanga",
  },
  {
    casteName: "Devanga Chettiar",
  },
  {
    casteName: "Devar/Thevar/Mukkulathor",
  },
  {
    casteName: "Devrukhe Brahmin",
  },
  {
    casteName: "Dhanak",
  },
  {
    casteName: "Dhangar",
  },
  {
    casteName: "Dheevara",
  },
  {
    casteName: "Dhiman",
  },
  {
    casteName: "Dhiman Brahmin",
  },
  {
    casteName: "Dhoba",
  },
  {
    casteName: "Dhobi",
  },
  {
    casteName: "Dhor / Kakkayya",
  },
  {
    casteName: "Dommala",
  },
  {
    casteName: "Dosar / Dusra",
  },
  {
    casteName: "Dravida Brahmin",
  },
  {
    casteName: "Dumal",
  },
  {
    casteName: "Dusadh (Paswan)",
  },
  {
    casteName: "Ediga",
  },
  {
    casteName: "Ediga /Goud (Balija)",
  },
  {
    casteName: "Elur Chetty",
  },
  {
    casteName: "Embrandiri Brahmin",
  },
  {
    casteName: "Evangelist",
  },
  {
    casteName: "Ezhava",
  },
  {
    casteName: "Ezhava Panicker",
  },
  {
    casteName: "Ezhava Thandan",
  },
  {
    casteName: "Ezhuthachan",
  },
  {
    casteName: "Gabit",
  },
  {
    casteName: "Gahoi",
  },
  {
    casteName: "Gajula / Kavarai",
  },
  {
    casteName: "Ganda",
  },
  {
    casteName: "Gandha Vanika",
  },
  {
    casteName: "Gandla",
  },
  {
    casteName: "Gandla / Ganiga",
  },
  {
    casteName: "Ganiga",
  },
  {
    casteName: "Garhwali",
  },
  {
    casteName: "Garhwali Brahmin",
  },
  {
    casteName: "Gatti",
  },
  {
    casteName: "Gaur Brahmin",
  },
  {
    casteName: "Gavandi",
  },
  {
    casteName: "Gavara",
  },
  {
    casteName: "Gawali",
  },
  {
    casteName: "Ghisadi",
  },
  {
    casteName: "Ghumar",
  },
  {
    casteName: "Goala",
  },
  {
    casteName: "Goan",
  },
  {
    casteName: "Gomantak",
  },
  {
    casteName: "Gond",
  },
  {
    casteName: "Gondhali",
  },
  {
    casteName: "Gopal / Gopala",
  },
  {
    casteName: "Goswami/Gosavi Brahmin",
  },
  {
    casteName: "Goud",
  },
  {
    casteName: "Gounder",
  },
  {
    casteName: "Gounder - Kongu Vellala Gounder",
  },
  {
    casteName: "Gounder - Nattu Gounder",
  },
  {
    casteName: "Gounder - Others",
  },
  {
    casteName: "Gounder - Urali Gounder",
  },
  {
    casteName: "Gounder - Vanniya Kula Kshatriyar",
  },
  {
    casteName: "Gounder - Vettuva Gounder",
  },
  {
    casteName: "Gowda",
  },
  {
    casteName: "Gowda - Kuruba Gowda",
  },
  {
    casteName: "Gramani",
  },
  {
    casteName: "Gudia",
  },
  {
    casteName: "Gujar Gaur Brahmin",
  },
  {
    casteName: "Gujjar",
  },
  {
    casteName: "Gulahre",
  },
  {
    casteName: "Gupta",
  },
  {
    casteName: "Guptan",
  },
  {
    casteName: "Gurav",
  },
  {
    casteName: "Gurjar",
  },
  {
    casteName: "Gurukkal Brahmin",
  },
  {
    casteName: "Haihaivanshi",
  },
  {
    casteName: "Halba Koshti",
  },
  {
    casteName: "Halua Brahmin",
  },
  {
    casteName: "Havyaka Brahmin",
  },
  {
    casteName: "Heggade",
  },
  {
    casteName: "Helava",
  },
  {
    casteName: "Holar",
  },
  {
    casteName: "Hoysala Brahmin",
  },
  {
    casteName: "Hugar (Jeer)",
  },
  {
    casteName: "Hyderabadi Sindhi",
  },
  {
    casteName: "Illaththu Pillai",
  },
  {
    casteName: "Intercaste",
  },
  {
    casteName: "Irani",
  },
  {
    casteName: "Isai Vellalar",
  },
  {
    casteName: "Iyengar Brahmin",
  },
  {
    casteName: "Iyer Brahmin",
  },
  {
    casteName: "Jaalari",
  },
  {
    casteName: "Jacobite",
  },
  {
    casteName: "Jain - Agarwal",
  },
  {
    casteName: "Jain - Asati",
  },
  {
    casteName: "Jain - Ayodhyavasi",
  },
  {
    casteName: "Jain - Bagherwal",
  },
  {
    casteName: "Jain - Bania",
  },
  {
    casteName: "Jain - Barhiya",
  },
  {
    casteName: "Jain - Charanagare",
  },
  {
    casteName: "Jain - Chaturtha",
  },
  {
    casteName: "Jain - Dhakada",
  },
  {
    casteName: "Jain - Gahoi / Grihapati",
  },
  {
    casteName: "Jain - Golalare / Kharaua",
  },
  {
    casteName: "Jain - Golapurva",
  },
  {
    casteName: "Jain - Golsinghare",
  },
  {
    casteName: "Jain - Harada",
  },
  {
    casteName: "Jain - Humad / Humbad",
  },
  {
    casteName: "Jain - Intercaste",
  },
  {
    casteName: "Jain - Jaiswal",
  },
  {
    casteName: "Jain - KVO",
  },
  {
    casteName: "Jain - Kambhoja",
  },
  {
    casteName: "Jain - Kasar",
  },
  {
    casteName: "Jain - Kathanere",
  },
  {
    casteName: "Jain - Khandelwal",
  },
  {
    casteName: "Jain - Kharaua",
  },
  {
    casteName: "Jain - Kutchi",
  },
  {
    casteName: "Jain - Lamechu",
  },
  {
    casteName: "Jain - Nema",
  },
  {
    casteName: "Jain - Oswal",
  },
  {
    casteName: "Jain - Others",
  },
  {
    casteName: "Jain - Padmavati Porwal",
  },
  {
    casteName: "Jain - Palliwal",
  },
  {
    casteName: "Jain - Panchama",
  },
  {
    casteName: "Jain - Parmar",
  },
  {
    casteName: "Jain - Parwar / Paravara",
  },
  {
    casteName: "Jain - Porwad / Porwal",
  },
  {
    casteName: "Jain - Porwal",
  },
  {
    casteName: "Jain - Saitwal",
  },
  {
    casteName: "Jain - Samanar / Nayinar",
  },
  {
    casteName: "Jain - Samiya",
  },
  {
    casteName: "Jain - Sarak",
  },
  {
    casteName: "Jain - Shrimal",
  },
  {
    casteName: "Jain - Unspecified",
  },
  {
    casteName: "Jain - Upadhyaya",
  },
  {
    casteName: "Jain - Vaishya",
  },
  {
    casteName: "Jain - Veerwal",
  },
  {
    casteName: "Jaiswal",
  },
  {
    casteName: "Jandra",
  },
  {
    casteName: "Jangam",
  },
  {
    casteName: "Jangid Brahmin",
  },
  {
    casteName: "Jangra - Brahmin",
  },
  {
    casteName: "Jat",
  },
  {
    casteName: "Jatav",
  },
  {
    casteName: "Jeer",
  },
  {
    casteName: "Jehovah's Witnesses",
  },
  {
    casteName: "Jetty/Malla",
  },
  {
    casteName: "Jhadav",
  },
  {
    casteName: "Jhadua Brahmin",
  },
  {
    casteName: "Jijhotia Brahmin",
  },
  {
    casteName: "Jogi (Nath)",
  },
  {
    casteName: "Julaha",
  },
  {
    casteName: "Kachara",
  },
  {
    casteName: "Kadava Patel",
  },
  {
    casteName: "Kadia",
  },
  {
    casteName: "Kahar",
  },
  {
    casteName: "Kaibarta",
  },
  {
    casteName: "Kaikaala",
  },
  {
    casteName: "Kalal",
  },
  {
    casteName: "Kalanji",
  },
  {
    casteName: "Kalar",
  },
  {
    casteName: "Kalinga",
  },
  {
    casteName: "Kalinga Vysya",
  },
  {
    casteName: "Kalita",
  },
  {
    casteName: "Kalwar",
  },
  {
    casteName: "Kamboj",
  },
  {
    casteName: "Kamma",
  },
  {
    casteName: "Kamma Naidu",
  },
  {
    casteName: "Kanakkan Padanna",
  },
  {
    casteName: "Kandara",
  },
  {
    casteName: "Kansari",
  },
  {
    casteName: "Kansyakaar",
  },
  {
    casteName: "Kanyakubj Brahmin",
  },
  {
    casteName: "Kanykubj Bania",
  },
  {
    casteName: "Kapu",
  },
  {
    casteName: "Kapu Naidu",
  },
  {
    casteName: "Kapu Reddy",
  },
  {
    casteName: "Karakala Bhakthula",
  },
  {
    casteName: "Karana",
  },
  {
    casteName: "Karhade Brahmin",
  },
  {
    casteName: "Karkathar",
  },
  {
    casteName: "Karmakar",
  },
  {
    casteName: "Karni Bhakthula",
  },
  {
    casteName: "Karuneegar",
  },
  {
    casteName: "Kasar",
  },
  {
    casteName: "Kasaundhan",
  },
  {
    casteName: "Kashyap",
  },
  {
    casteName: "Kasukara",
  },
  {
    casteName: "Katiya",
  },
  {
    casteName: "Kavara",
  },
  {
    casteName: "Kavuthiyya/Ezhavathy",
  },
  {
    casteName: "Kayastha",
  },
  {
    casteName: "Kayastha (Bengali)",
  },
  {
    casteName: "Kerala Mudali",
  },
  {
    casteName: "Keshri (Kesarwani)",
  },
  {
    casteName: "Khandayat",
  },
  {
    casteName: "Khandelwal",
  },
  {
    casteName: "Kharwa",
  },
  {
    casteName: "Kharwar",
  },
  {
    casteName: "Khatik",
  },
  {
    casteName: "Khatri",
  },
  {
    casteName: "Kirar",
  },
  {
    casteName: "Knanaya",
  },
  {
    casteName: "Knanaya Catholic",
  },
  {
    casteName: "Knanaya Jacobite",
  },
  {
    casteName: "Kodava",
  },
  {
    casteName: "Kodikal Pillai",
  },
  {
    casteName: "Koiri",
  },
  {
    casteName: "Kokanastha Brahmin",
  },
  {
    casteName: "Kokanastha Maratha",
  },
  {
    casteName: "Koli",
  },
  {
    casteName: "Koli Mahadev",
  },
  {
    casteName: "Koli Patel",
  },
  {
    casteName: "Komti /Arya Vaishya",
  },
  {
    casteName: "Kongu Chettiar",
  },
  {
    casteName: "Kongu Nadar",
  },
  {
    casteName: "Kongu Vellala Gounder",
  },
  {
    casteName: "Konkani",
  },
  {
    casteName: "Korama",
  },
  {
    casteName: "Kori",
  },
  {
    casteName: "Kori/Koli",
  },
  {
    casteName: "Kosthi",
  },
  {
    casteName: "Kota Brahmin",
  },
  {
    casteName: "Krishnavaka",
  },
  {
    casteName: "Kshatriya",
  },
  {
    casteName: "Kshatriya Kurmi",
  },
  {
    casteName: "Kshatriya Raju",
  },
  {
    casteName: "Kudumbi",
  },
  {
    casteName: "Kulal",
  },
  {
    casteName: "Kulalar",
  },
  {
    casteName: "Kulin Brahmin",
  },
  {
    casteName: "Kulita",
  },
  {
    casteName: "Kumaoni Brahmin",
  },
  {
    casteName: "Kumaoni Rajput",
  },
  {
    casteName: "Kumawat",
  },
  {
    casteName: "Kumbhakar",
  },
  {
    casteName: "Kumbhar",
  },
  {
    casteName: "Kumhar",
  },
  {
    casteName: "Kummari",
  },
  {
    casteName: "Kunbi",
  },
  {
    casteName: "Kunbi Lonari",
  },
  {
    casteName: "Kunbi Maratha",
  },
  {
    casteName: "Kunbi Tirale",
  },
  {
    casteName: "Kuravan",
  },
  {
    casteName: "Kurmi",
  },
  {
    casteName: "Kurmi/Kurmi Kshatriya",
  },
  {
    casteName: "Kurni",
  },
  {
    casteName: "Kuruba",
  },
  {
    casteName: "Kuruhina Shetty",
  },
  {
    casteName: "Kuruhini Chetty",
  },
  {
    casteName: "Kurumbar",
  },
  {
    casteName: "Kuruva",
  },
  {
    casteName: "Kushwaha (Koiri)",
  },
  {
    casteName: "Kutchi",
  },
  {
    casteName: "Lad",
  },
  {
    casteName: "Lambadi",
  },
  {
    casteName: "Larai Sindhi",
  },
  {
    casteName: "Larkana Sindhi",
  },
  {
    casteName: "Latin Catholic",
  },
  {
    casteName: "Latter day saints",
  },
  {
    casteName: "Laxminarayan gola",
  },
  {
    casteName: "Leva patel",
  },
  {
    casteName: "Leva patil",
  },
  {
    casteName: "Linga Balija",
  },
  {
    casteName: "Lingayath",
  },
  {
    casteName: "Lodhi Rajput",
  },
  {
    casteName: "Lohana",
  },
  {
    casteName: "Lohana Sindhi",
  },
  {
    casteName: "Lohar",
  },
  {
    casteName: "Loniya",
  },
  {
    casteName: "Lubana",
  },
  {
    casteName: "Lutheran",
  },
  {
    casteName: "Madhesiya/Kanu/Halwai",
  },
  {
    casteName: "Madhwa Brahmin",
  },
  {
    casteName: "Madiga",
  },
  {
    casteName: "Mahajan",
  },
  {
    casteName: "Mahar",
  },
  {
    casteName: "Mahendra",
  },
  {
    casteName: "Maheshwari",
  },
  {
    casteName: "Maheshwari / Meshri",
  },
  {
    casteName: "Mahishya",
  },
  {
    casteName: "Mahor",
  },
  {
    casteName: "Mahuri",
  },
  {
    casteName: "Mair Rajput Swarnkar",
  },
  {
    casteName: "Maithil Brahmin",
  },
  {
    casteName: "Majabi",
  },
  {
    casteName: "Mala",
  },
  {
    casteName: "Malabar Independent Syrian Church",
  },
  {
    casteName: "Malankara Catholic",
  },
  {
    casteName: "Mali",
  },
  {
    casteName: "Mallah",
  },
  {
    casteName: "Malviya Brahmin",
  },
  {
    casteName: "Malwani",
  },
  {
    casteName: "Mangalorean",
  },
  {
    casteName: "Manipuri",
  },
  {
    casteName: "Manjapudur Chettiar",
  },
  {
    casteName: "Mannadiyar",
  },
  {
    casteName: "Mannan / Velan / Vannan",
  },
  {
    casteName: "Mapila",
  },
  {
    casteName: "Maratha",
  },
  {
    casteName: "Maratha Kshatriya",
  },
  {
    casteName: "Marthoma",
  },
  {
    casteName: "Maruthuvar",
  },
  {
    casteName: "Matang",
  },
  {
    casteName: "Mathur",
  },
  {
    casteName: "Mathur Vaishya",
  },
  {
    casteName: "Matia Patel",
  },
  {
    casteName: "Maurya / Shakya",
  },
  {
    casteName: "Meena",
  },
  {
    casteName: "Meenavar",
  },
  {
    casteName: "Meghwal",
  },
  {
    casteName: "Mehra",
  },
  {
    casteName: "Melkite",
  },
  {
    casteName: "Mennonite",
  },
  {
    casteName: "Mera",
  },
  {
    casteName: "Meru Darji",
  },
  {
    casteName: "Methodist",
  },
  {
    casteName: "Mochi",
  },
  {
    casteName: "Modak",
  },
  {
    casteName: "Modh Brahmin",
  },
  {
    casteName: "Modh Ghanchi",
  },
  {
    casteName: "Modi",
  },
  {
    casteName: "Modikarlu",
  },
  {
    casteName: "Mogaveera",
  },
  {
    casteName: "Mohyal Brahmin",
  },
  {
    casteName: "Moravian",
  },
  {
    casteName: "Mudaliyar",
  },
  {
    casteName: "Mudiraj",
  },
  {
    casteName: "Mukkulathor",
  },
  {
    casteName: "Munnuru Kapu",
  },
  {
    casteName: "Muslim - Ansari",
  },
  {
    casteName: "Muslim - Arain",
  },
  {
    casteName: "Muslim - Awan",
  },
  {
    casteName: "Muslim - Bohra",
  },
  {
    casteName: "Muslim - Dekkani",
  },
  {
    casteName: "Muslim - Dudekula",
  },
  {
    casteName: "Muslim - Hanafi",
  },
  {
    casteName: "Muslim - Jat",
  },
  {
    casteName: "Muslim - Khoja",
  },
  {
    casteName: "Muslim - Lebbai",
  },
  {
    casteName: "Muslim - Malik",
  },
  {
    casteName: "Muslim - Mapila",
  },
  {
    casteName: "Muslim - Maraicar",
  },
  {
    casteName: "Muslim - Memon",
  },
  {
    casteName: "Muslim - Mughal",
  },
  {
    casteName: "Muslim - Others",
  },
  {
    casteName: "Muslim - Pathan",
  },
  {
    casteName: "Muslim - Qureshi",
  },
  {
    casteName: "Muslim - Rajput",
  },
  {
    casteName: "Muslim - Rowther",
  },
  {
    casteName: "Muslim - Shafi",
  },
  {
    casteName: "Muslim - Sheikh",
  },
  {
    casteName: "Muslim - Siddiqui",
  },
  {
    casteName: "Muslim - Syed",
  },
  {
    casteName: "Muslim - UnSpecified",
  },
  {
    casteName: "Musukama",
  },
  {
    casteName: "Muthuraja",
  },
  {
    casteName: "Naagavamsam",
  },
  {
    casteName: "Nabit",
  },
  {
    casteName: "Nadar",
  },
  {
    casteName: "Nagar Brahmin",
  },
  {
    casteName: "Nagaralu",
  },
  {
    casteName: "Nai / Nayi Brahmin",
  },
  {
    casteName: "Naicker",
  },
  {
    casteName: "Naicker - Others",
  },
  {
    casteName: "Naicker - Vanniya Kula Kshatriyar",
  },
  {
    casteName: "Naidu",
  },
  {
    casteName: "Naik",
  },
  {
    casteName: "Naika",
  },
  {
    casteName: "Nair",
  },
  {
    casteName: "Namasudra / Namassej",
  },
  {
    casteName: "Nambiar",
  },
  {
    casteName: "Namboodiri Brahmin",
  },
  {
    casteName: "Namdarlu",
  },
  {
    casteName: "Nanjil Mudali",
  },
  {
    casteName: "Nanjil Nattu Vellalar",
  },
  {
    casteName: "Nanjil Vellalar",
  },
  {
    casteName: "Nanjil pillai",
  },
  {
    casteName: "Nankudi Vellalar",
  },
  {
    casteName: "Napit",
  },
  {
    casteName: "Narmadiya Brahmin",
  },
  {
    casteName: "Nath",
  },
  {
    casteName: "Nattu Gounder",
  },
  {
    casteName: "Nattukottai Chettiar",
  },
  {
    casteName: "Nayaka",
  },
  {
    casteName: "Neeli",
  },
  {
    casteName: "Neeli Saali",
  },
  {
    casteName: "Nema",
  },
  {
    casteName: "Neo Buddhist",
  },
  {
    casteName: "Nepali",
  },
  {
    casteName: "Nessi",
  },
  {
    casteName: "Nhavi",
  },
  {
    casteName: "Niari",
  },
  {
    casteName: "Niyogi Brahmin",
  },
  {
    casteName: "Not Specified",
  },
  {
    casteName: "Odan",
  },
  {
    casteName: "Ontari",
  },
  {
    casteName: "Oraon",
  },
  {
    casteName: "Orthodox",
  },
  {
    casteName: "Oswal",
  },
  {
    casteName: "Otari",
  },
  {
    casteName: "Others",
  },
  {
    casteName: "Othuvaar",
  },
  {
    casteName: "Padmasali",
  },
  {
    casteName: "Padmashali",
  },
  {
    casteName: "Padmavati Porwal",
  },
  {
    casteName: "Pagadala",
  },
  {
    casteName: "Pal",
  },
  {
    casteName: "Pallan / Devandra Kula Vellalan",
  },
  {
    casteName: "Panan",
  },
  {
    casteName: "Panchal",
  },
  {
    casteName: "Panda Brahmin",
  },
  {
    casteName: "Pandaram",
  },
  {
    casteName: "Pandit Brahmin",
  },
  {
    casteName: "Pandiya Vellalar",
  },
  {
    casteName: "Panicker",
  },
  {
    casteName: "Pannirandam Chettiar",
  },
  {
    casteName: "Paravan / Bharatar",
  },
  {
    casteName: "Parit",
  },
  {
    casteName: "Parkavakulam / Udayar",
  },
  {
    casteName: "Parsi",
  },
  {
    casteName: "Partraj",
  },
  {
    casteName: "Parvatha Rajakulam",
  },
  {
    casteName: "Pasi",
  },
  {
    casteName: "Paswan",
  },
  {
    casteName: "Paswan / Dusadh",
  },
  {
    casteName: "Patel",
  },
  {
    casteName: "Pathare Prabhu",
  },
  {
    casteName: "Patil",
  },
  {
    casteName: "Patnaick/Sistakaranam",
  },
  {
    casteName: "Patra",
  },
  {
    casteName: "Pattinavar",
  },
  {
    casteName: "Pattusali",
  },
  {
    casteName: "Patwa",
  },
  {
    casteName: "Pentecost",
  },
  {
    casteName: "Perika",
  },
  {
    casteName: "Perika/Puragiri Kshatriya",
  },
  {
    casteName: "Pillai",
  },
  {
    casteName: "Poddar",
  },
  {
    casteName: "Poosala",
  },
  {
    casteName: "Porwal",
  },
  {
    casteName: "Porwal / Porwar",
  },
  {
    casteName: "Poundra",
  },
  {
    casteName: "Prajapati",
  },
  {
    casteName: "Presbyterian",
  },
  {
    casteName: "Protestant",
  },
  {
    casteName: "Pulaya / Cheruman",
  },
  {
    casteName: "Pushkarna Brahmin",
  },
  {
    casteName: "Radhi / Niari",
  },
  {
    casteName: "Raigar",
  },
  {
    casteName: "Rajaka / Vannar",
  },
  {
    casteName: "Rajastani",
  },
  {
    casteName: "Rajbhar",
  },
  {
    casteName: "Rajbonshi",
  },
  {
    casteName: "Rajpurohit",
  },
  {
    casteName: "Rajput",
  },
  {
    casteName: "Raju / Kshatriya Raju",
  },
  {
    casteName: "Ramanandi",
  },
  {
    casteName: "Ramdasia",
  },
  {
    casteName: "Ramgariah",
  },
  {
    casteName: "Ramoshi",
  },
  {
    casteName: "Rarhi Brahmin",
  },
  {
    casteName: "Rastogi",
  },
  {
    casteName: "Rathi",
  },
  {
    casteName: "Rauniar",
  },
  {
    casteName: "Ravidasia",
  },
  {
    casteName: "Rawat",
  },
  {
    casteName: "Reddy",
  },
  {
    casteName: "Reformed Baptist",
  },
  {
    casteName: "Reformed Presbyterian",
  },
  {
    casteName: "Relli",
  },
  {
    casteName: "Rigvedi Brahmin",
  },
  {
    casteName: "Rohiri Sindhi",
  },
  {
    casteName: "Rohit / Chamar",
  },
  {
    casteName: "Roman Catholic",
  },
  {
    casteName: "Ror",
  },
  {
    casteName: "Rudraj Brahmin",
  },
  {
    casteName: "SC",
  },
  {
    casteName: "SKP",
  },
  {
    casteName: "ST",
  },
  {
    casteName: "Sadgope",
  },
  {
    casteName: "Sadhu Chetty",
  },
  {
    casteName: "Sagara (Uppara)",
  },
  {
    casteName: "Saha",
  },
  {
    casteName: "Sahiti Sindhi",
  },
  {
    casteName: "Sahu",
  },
  {
    casteName: "Saini",
  },
  {
    casteName: "Saiva Pillai Thanjavur",
  },
  {
    casteName: "Saiva Pillai Tirunelveli",
  },
  {
    casteName: "Saiva Vellan chettiar",
  },
  {
    casteName: "Sakaldwipi Brahmin",
  },
  {
    casteName: "Sakkhar Sindhi",
  },
  {
    casteName: "Saliya",
  },
  {
    casteName: "Saliyar",
  },
  {
    casteName: "Samagar",
  },
  {
    casteName: "Sambava",
  },
  {
    casteName: "Sanadya Brahmin",
  },
  {
    casteName: "Sanketi Brahmin",
  },
  {
    casteName: "Saraswat Brahmin",
  },
  {
    casteName: "Saryuparin Brahmin",
  },
  {
    casteName: "Sathwara",
  },
  {
    casteName: "Satnami",
  },
  {
    casteName: "Savji",
  },
  {
    casteName: "Sehwani Sindhi",
  },
  {
    casteName: "Senai Thalaivar",
  },
  {
    casteName: "Senguntha Mudaliyar",
  },
  {
    casteName: "Sengunthar/Kaikolar",
  },
  {
    casteName: "Settibalija",
  },
  {
    casteName: "Setty Balija",
  },
  {
    casteName: "Seventh-day Adventist",
  },
  {
    casteName: "Shah",
  },
  {
    casteName: "Shaw / Sahu/Teli",
  },
  {
    casteName: "Shettigar",
  },
  {
    casteName: "Shetty",
  },
  {
    casteName: "Shikarpuri Sindhi",
  },
  {
    casteName: "Shilpkar",
  },
  {
    casteName: "Shimpi/Namdev",
  },
  {
    casteName: "Shivhalli Brahmin",
  },
  {
    casteName: "Shrimali Brahmin",
  },
  {
    casteName: "Sikh - Ahluwalia",
  },
  {
    casteName: "Sikh - Arora",
  },
  {
    casteName: "Sikh - Bhatia",
  },
  {
    casteName: "Sikh - Bhatra",
  },
  {
    casteName: "Sikh - Ghumar",
  },
  {
    casteName: "Sikh - Intercaste",
  },
  {
    casteName: "Sikh - Jat",
  },
  {
    casteName: "Sikh - Kamboj",
  },
  {
    casteName: "Sikh - Khatri",
  },
  {
    casteName: "Sikh - Kshatriya",
  },
  {
    casteName: "Sikh - Lubana",
  },
  {
    casteName: "Sikh - Majabi",
  },
  {
    casteName: "Sikh - Nai",
  },
  {
    casteName: "Sikh - Others",
  },
  {
    casteName: "Sikh - Rai",
  },
  {
    casteName: "Sikh - Rajput",
  },
  {
    casteName: "Sikh - Ramdasia",
  },
  {
    casteName: "Sikh - Ramgharia",
  },
  {
    casteName: "Sikh - Ravidasia",
  },
  {
    casteName: "Sikh - Saini",
  },
  {
    casteName: "Sikh - Tonk Kshatriya",
  },
  {
    casteName: "Sikh - Unspecified",
  },
  {
    casteName: "Sikhwal Brahmin",
  },
  {
    casteName: "Sindhi",
  },
  {
    casteName: "Sindhi-Amil",
  },
  {
    casteName: "Sindhi-Baibhand",
  },
  {
    casteName: "Sindhi-Bhanusali",
  },
  {
    casteName: "Sindhi-Bhatia",
  },
  {
    casteName: "Sindhi-Chhapru",
  },
  {
    casteName: "Sindhi-Dadu",
  },
  {
    casteName: "Sindhi-Hyderabadi",
  },
  {
    casteName: "Sindhi-Larai",
  },
  {
    casteName: "Sindhi-Larkana",
  },
  {
    casteName: "Sindhi-Lohana",
  },
  {
    casteName: "Sindhi-Rohiri",
  },
  {
    casteName: "Sindhi-Sahiti",
  },
  {
    casteName: "Sindhi-Sakkhar",
  },
  {
    casteName: "Sindhi-Sehwani",
  },
  {
    casteName: "Sindhi-Shikarpuri",
  },
  {
    casteName: "Sindhi-Thatai",
  },
  {
    casteName: "Smartha Brahmin",
  },
  {
    casteName: "Sonar",
  },
  {
    casteName: "Soni",
  },
  {
    casteName: "Sonkar",
  },
  {
    casteName: "Sourashtra",
  },
  {
    casteName: "Sozhia Chetty",
  },
  {
    casteName: "Sozhiya Vellalar",
  },
  {
    casteName: "Sri Vaishnava Brahmin",
  },
  {
    casteName: "Srisayana",
  },
  {
    casteName: "St. Thomas Evangelical",
  },
  {
    casteName: "Stanika Brahmin",
  },
  {
    casteName: "Sugali (Naika)",
  },
  {
    casteName: "Sunari",
  },
  {
    casteName: "Sundhi",
  },
  {
    casteName: "Surya Balija",
  },
  {
    casteName: "Suthar",
  },
  {
    casteName: "Swakula Sali",
  },
  {
    casteName: "Swarnakar",
  },
  {
    casteName: "Syrian Catholic",
  },
  {
    casteName: "Syrian Jacobite",
  },
  {
    casteName: "Syrian Orthodox",
  },
  {
    casteName: "Syro Malabar",
  },
  {
    casteName: "Tamboli",
  },
  {
    casteName: "Tammali",
  },
  {
    casteName: "Tanti",
  },
  {
    casteName: "Tantubai",
  },
  {
    casteName: "Telaga",
  },
  {
    casteName: "Teli",
  },
  {
    casteName: "Telugupatti",
  },
  {
    casteName: "Thakkar",
  },
  {
    casteName: "Thakore",
  },
  {
    casteName: "Thakur",
  },
  {
    casteName: "Thandan",
  },
  {
    casteName: "Tharakan",
  },
  {
    casteName: "Thatai Sindhi",
  },
  {
    casteName: "Thigala",
  },
  {
    casteName: "Thiyya",
  },
  {
    casteName: "Thiyya Thandan",
  },
  {
    casteName: "Thogata Veera Kshatriya",
  },
  {
    casteName: "Thogata Veerakshathriya",
  },
  {
    casteName: "Thondai Mandala Vellalar",
  },
  {
    casteName: "Thota",
  },
  {
    casteName: "Tibetan Buddhist",
  },
  {
    casteName: "Tili",
  },
  {
    casteName: "Togata",
  },
  {
    casteName: "Tonk Kshatriya",
  },
  {
    casteName: "Turupu Kapu",
  },
  {
    casteName: "Tyagi Brahmin",
  },
  {
    casteName: "Ummar / Umre / Bagaria",
  },
  {
    casteName: "Urali Gounder",
  },
  {
    casteName: "Urs",
  },
  {
    casteName: "Vada Balija",
  },
  {
    casteName: "Vadambar",
  },
  {
    casteName: "Vadar",
  },
  {
    casteName: "Vaddera",
  },
  {
    casteName: "Vadugan",
  },
  {
    casteName: "Vaidiki Brahmin",
  },
  {
    casteName: "Vaikhanasa Brahmin",
  },
  {
    casteName: "Vaish",
  },
  {
    casteName: "Vaishnav",
  },
  {
    casteName: "Vaishnav Dishaval",
  },
  {
    casteName: "Vaishnav Kapol",
  },
  {
    casteName: "Vaishnav Khadyata",
  },
  {
    casteName: "Vaishnav Lad",
  },
  {
    casteName: "Vaishnav Modh",
  },
  {
    casteName: "Vaishnav Porvad",
  },
  {
    casteName: "Vaishnav Shrimali",
  },
  {
    casteName: "Vaishnav Sorathaiya",
  },
  {
    casteName: "Vaishnav Vania",
  },
  {
    casteName: "Vaishnava",
  },
  {
    casteName: "Vaishya",
  },
  {
    casteName: "Vaishya Vani",
  },
  {
    casteName: "Valar",
  },
  {
    casteName: "Valluvan",
  },
  {
    casteName: "Valmiki",
  },
  {
    casteName: "Valsad",
  },
  {
    casteName: "Vani",
  },
  {
    casteName: "Vani / Vaishya",
  },
  {
    casteName: "Vania",
  },
  {
    casteName: "Vanika Vyshya",
  },
  {
    casteName: "Vaniya",
  },
  {
    casteName: "Vaniya Chettiar",
  },
  {
    casteName: "Vanjara",
  },
  {
    casteName: "Vanjari",
  },
  {
    casteName: "Vankar",
  },
  {
    casteName: "Vannar",
  },
  {
    casteName: "Vannia Kula Kshatriyar",
  },
  {
    casteName: "Vanyakulakshatriya",
  },
  {
    casteName: "Variar",
  },
  {
    casteName: "Varshney",
  },
  {
    casteName: "Varshney (Barahseni)",
  },
  {
    casteName: "Veera Saivam",
  },
  {
    casteName: "Veerakodi Vellala",
  },
  {
    casteName: "Velaan",
  },
  {
    casteName: "Velama",
  },
  {
    casteName: "Velanadu Brahmin",
  },
  {
    casteName: "Vellala Pillai",
  },
  {
    casteName: "Vellalar",
  },
  {
    casteName: "Vellan Chettiars",
  },
  {
    casteName: "Veluthedathu Nair",
  },
  {
    casteName: "Vettuva Gounder",
  },
  {
    casteName: "Vettuvan",
  },
  {
    casteName: "Vijayvargia",
  },
  {
    casteName: "Vilakithala Nair",
  },
  {
    casteName: "Vilakkithala Nair",
  },
  {
    casteName: "Vishwakarma",
  },
  {
    casteName: "Viswabrahmin",
  },
  {
    casteName: "Vokkaliga",
  },
  {
    casteName: "Vyas Brahmin",
  },
  {
    casteName: "Vysya",
  },
  {
    casteName: "Yadav",
  },
  {
    casteName: "Yadava Naidu",
  },
  {
    casteName: "Yellapu",
  },
  {
    casteName: "Caste no bar",
  },
];
