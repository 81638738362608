import React from "react";

const Account = () => {
  return (
    <div className="d-flex flex-column-fluid">
      <div className="container">
        <div className="d-flex flex-row">
          <div className="flex-row-fluid ml-lg-12">
            <div className="card card-custom">
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label font-weight-bolder text-dark">
                    Account Information
                  </h3>
                  <span className="text-muted font-weight-bold font-size-sm mt-1">
                    Change your account settings
                  </span>
                </div>
                <div className="card-toolbar">
                  <button type="reset" className="btn btn-success mr-2">
                    Save Changes
                  </button>
                  <button type="reset" className="btn btn-secondary">
                    Cancel
                  </button>
                </div>
              </div>

              <form className="form">
                <div className="card-body">
                  <div className="row">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-6">
                      <h5 className="font-weight-bold mb-6">Account:</h5>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Username
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div className="spinner spinner-sm spinner-success spinner-right">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          type="text"
                          defaultValue="nick84"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Email Address
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div className="input-group input-group-lg input-group-solid">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="la la-at"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          defaultValue="nick.watson@loop.com"
                          placeholder="Email"
                        />
                      </div>
                      <span className="form-text text-muted">
                        Email will not be publicly displayed.
                        <a href={() => false} className="kt-link">
                          Learn more
                        </a>
                        .
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Language
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <select className="form-control form-control-lg form-control-solid">
                        <option value="select">Select Language...</option>
                        <option value="en" selected="selected">
                          English
                        </option>
                        <option value="en-gb">
                          English UK - British English
                        </option>
                        <option value="ta">தமிழ் - Tamil</option>
                        <option value="kn">ಕನ್ನಡ - Kannada</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Time Zone
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <select className="form-control form-control-lg form-control-solid">
                        <option
                          data-offset="-39600"
                          defaultValue="International Date Line West"
                        >
                          (GMT-11:00) International Date Line West
                        </option>
                        <option
                          data-offset="-39600"
                          defaultValue="Midway Island"
                        >
                          (GMT-11:00) Midway Island
                        </option>
                        <option data-offset="-39600" defaultValue="Samoa">
                          (GMT-11:00) Samoa
                        </option>
                        <option data-offset="-36000" defaultValue="Hawaii">
                          (GMT-10:00) Hawaii
                        </option>
                        <option data-offset="-28800" defaultValue="Alaska">
                          (GMT-08:00) Alaska
                        </option>
                        <option
                          data-offset="-25200"
                          defaultValue="Pacific Time (US &amp; Canada)"
                        >
                          (GMT-07:00) Pacific Time (US &amp; Canada)
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row align-items-center">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Communication
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div className="checkbox-inline">
                        <label className="checkbox">
                          <input type="checkbox" defaultChecked="checked" />
                          <span></span>Email
                        </label>
                        <label className="checkbox">
                          <input type="checkbox" defaultChecked="checked" />
                          <span></span>SMS
                        </label>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span></span>Phone
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="separator separator-dashed my-5"></div>

                  <div className="row">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-6">
                      <h5 className="font-weight-bold mb-6">Security:</h5>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Login verification
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <button
                        type="button"
                        className="btn btn-light-primary font-weight-bold btn-sm"
                      >
                        Setup login verification
                      </button>
                      <p className="form-text text-muted pt-2">
                        After you log in, you will be asked for additional
                        information to confirm your identity and protect your
                        account from being compromised.
                        <a href={() => false} className="font-weight-bold">
                          Learn more
                        </a>
                        .
                      </p>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Password reset verification
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div className="checkbox-inline">
                        <label className="checkbox m-0">
                          <input type="checkbox" />
                          <span></span>Require personal information to reset
                          your password.
                        </label>
                      </div>
                      <p className="form-text text-muted py-2">
                        For extra security, this requires you to confirm your
                        email or phone number when you reset your password.
                        <a href={() => false} className="font-weight-boldk">
                          Learn more
                        </a>
                        .
                      </p>
                      <button
                        type="button"
                        className="btn btn-light-danger font-weight-bold btn-sm"
                      >
                        Deactivate your account ?
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
