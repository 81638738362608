import React from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { getContactNumber } from "../store/selector";
import { actions } from "../store/slice";

const ContactDetails = () => {
  const contactDetails = useSelector(getContactNumber);
  const { emailId, contactNumber, whatsappNumber, alternativeContactNumber } =
    contactDetails;
  const dispatch = useDispatch();

  return (
    <div className="table-responsive">
      <table className="table">
        <tbody>
          <tr>
            <td>WhatsApp Number</td>
            <td>
              <PhoneInput
                id="whatsAppNumber"
                name="whatsAppNumber"
                country={"us"}
                inputStyle={{ width: "130px" }}
                value={whatsappNumber}
                onChange={(whatsappNumber) =>
                  dispatch(
                    actions.setContactDetails({
                      ...contactDetails,
                      whatsappNumber: whatsappNumber,
                    })
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Contact Number</td>
            <td>
              <PhoneInput
                id="contactNumber"
                name="contactNumber"
                inputStyle={{ width: "130px" }}
                country={"us"}
                value={contactNumber}
                onChange={(contactNumber) =>
                  dispatch(
                    actions.setContactDetails({
                      ...contactDetails,
                      contactNumber: contactNumber,
                    })
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Alternative Contact Number</td>
            <td>
              <PhoneInput
                id="alternativeContactNumber"
                name="alternativeContactNumber"
                inputStyle={{ width: "130px" }}
                country={"us"}
                value={alternativeContactNumber}
                onChange={(alternativeContactNumber) =>
                  dispatch(
                    actions.setContactDetails({
                      ...contactDetails,
                      alternativeContactNumber: alternativeContactNumber,
                    })
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Email Id</td>
            <td>
              <input
                type="text"
                className="form-control"
                id="emailId"
                placeholder="Email-Id"
                name="emailId"
                defaultValue={emailId}
                style={{ width: "130px" }}
                onChange={(e) =>
                  dispatch(
                    actions.setContactDetails({
                      ...contactDetails,
                      emailId: e.target.value,
                    })
                  )
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ContactDetails;
