import Box from "@mui/material/Box";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield";
import { getResidentialDetails } from "../store/selector";
import { actions } from "../store/slice";

const ResidentialDetails = () => {
  const residentialAddress = useSelector(getResidentialDetails);
  const dispatch = useDispatch();
  const { address, landmark, hometown, city, stateName, nationality, pincode } =
    residentialAddress;

  const residentialAddressFields = [
    {
      type: "text",
      text: "Address",
      id: "address",
      defaultValue: address,
      placeholder: "123, Xyz Street...",
      onChange: (e) =>
        dispatch(
          actions.setResidentialAddress({
            ...residentialAddress,
            address: e.target.value,
          })
        ),
    },
    {
      type: "text",
      text: "Landmark",
      id: "landmark",
      defaultValue: landmark,
      placeholder: "Near ...",
      onChange: (e) =>
        dispatch(
          actions.setResidentialAddress({
            ...residentialAddress,
            landmark: e.target.value,
          })
        ),
    },
    {
      type: "text",
      text: "Hometown",
      id: "hometown",
      defaultValue: hometown,
      placeholder: "Home Town",
      onChange: (e) =>
        dispatch(
          actions.setResidentialAddress({
            ...residentialAddress,
            hometown: e.target.value,
          })
        ),
    },
    {
      type: "text",
      text: "City",
      id: "city",
      defaultValue: city,
      placeholder: "Chennai",
      onChange: (e) =>
        dispatch(
          actions.setResidentialAddress({
            ...residentialAddress,
            city: e.target.value,
          })
        ),
    },
    {
      type: "text",
      text: "State Name",
      id: "stateName",
      defaultValue: stateName,
      placeholder: "Tamil Nadu",
      onChange: (e) =>
        dispatch(
          actions.setResidentialAddress({
            ...residentialAddress,
            stateName: e.target.value,
          })
        ),
    },
    {
      type: "text",
      text: "Nationality",
      id: "nationality",
      defaultValue: nationality,
      placeholder: "Indian",
      onChange: (e) =>
        dispatch(
          actions.setResidentialAddress({
            ...residentialAddress,
            nationality: e.target.value,
          })
        ),
    },
    {
      type: "text",
      text: "Pincode",
      id: "pincode",
      defaultValue: pincode,
      placeholder: "600 001",
      onChange: (e) =>
        dispatch(
          actions.setResidentialAddress({
            ...residentialAddress,
            pincode: e.target.value,
          })
        ),
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {residentialAddressFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default ResidentialDetails;
