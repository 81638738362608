import Box from "@mui/material/Box";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorToaster, successToaster } from "../../../utils/alerts";
import LableWithFormField from "../../../components/label-with-formfield";
import * as config from "../../../config/config";
// import { getLang } from "../../../store/selector";
import { getPersonalInfo } from "../store/selector";
import { actions } from "../store/slice";

const PersonalInfo = () => {
  // const lang = useSelector(getLang);
  const personalInfo = useSelector(getPersonalInfo);
  const dispatch = useDispatch();

  const {
    name,
    iAm,
    aboutMe,
    bloodGroup,
    complextion,
    maritalStatus,
    weight,
    height,
    profileImage,
  } = personalInfo;

  const uploadToCloudForProfileImage = async (e) => {
    const url = `${process.env.REACT_APP_CLOUD_URL}`;
    const preset = `${process.env.REACT_APP_CLOUD_PRESET}`;
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", preset);
    await axios({
      url: url,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: formData,
    })
      .then((res) => {
        successToaster("Image Uploaded Successfully!!!");
        dispatch(
          actions.setPersonalInfo({
            ...personalInfo,
            profileImage: res.data.secure_url,
          })
        );
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: personal-info.js:59 ~ uploadToCloudForProfileImage ~ err:",
          err
        );
        errorToaster("Sorry,Error in Uploading image...Try again later", err);
      });
  };

  const personalInfoFields = [
    {
      type: "text",
      text: "Name",
      id: "name",
      defaultValue: name,
      placeholder: "Muruga",
      onChange: (e) =>
        dispatch(
          actions.setPersonalInfo({
            ...personalInfo,
            name: e.target.value,
          })
        ),
    },
    {
      type: "text",
      text: "About Me",
      id: "aboutMe",
      defaultValue: aboutMe,
      placeholder: "Lorem Ipsum Lorem Ipsum Lorem Ipsum",
      onChange: (e) =>
        dispatch(
          actions.setPersonalInfo({
            ...personalInfo,
            aboutMe: e.target.value,
          })
        ),
    },
    {
      type: "select",
      text: "I am",
      id: "iAm",
      value: iAm,
      placeholder: "Groom",
      onChange: (e) =>
        dispatch(
          actions.setPersonalInfo({
            ...personalInfo,
            iAm: e.target.value,
          })
        ),
      array: config.iAm,
    },
    {
      type: "select",
      text: "Height (in feet)*",
      id: "height",
      value: height,
      placeholder: "5.1",
      onChange: (e) =>
        dispatch(
          actions.setPersonalInfo({
            ...personalInfo,
            height: e.target.value,
          })
        ),
      array: config.height,
    },
    {
      type: "text",
      text: "Weight (in Kg)*",
      id: "weight",
      defaultValue: weight,
      placeholder: "65",
      onChange: (e) =>
        dispatch(
          actions.setPersonalInfo({
            ...personalInfo,
            weight: e.target.value,
          })
        ),
    },
    {
      type: "select",
      text: "Blood Group",
      id: "bloodGroup",
      value: bloodGroup,
      placeholder: "A+ ve",
      onChange: (e) =>
        dispatch(
          actions.setPersonalInfo({
            ...personalInfo,
            bloodGroup: e.target.value,
          })
        ),
      array: config.bloodGroup,
    },
    {
      type: "text",
      text: "Complextion",
      id: "complextion",
      defaultValue: complextion,
      placeholder: "Whitish",
      onChange: (e) =>
        dispatch(
          actions.setPersonalInfo({
            ...personalInfo,
            complextion: e.target.value,
          })
        ),
    },
    {
      type: "select",
      text: "Marital Status",
      id: "maritalStatus",
      value: maritalStatus,
      placeholder: "A+ ve",
      onChange: (e) =>
        dispatch(
          actions.setPersonalInfo({
            ...personalInfo,
            maritalStatus: e.target.value,
          })
        ),
      array: config.maritalStatus,
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={2} style={{ borderTop: "none" }}>
                <div
                  className="media"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={profileImage || "../../assets/images/card_1.jpg"}
                    className="profile-img w mr-3"
                    alt="profile-pic"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ borderTop: "none" }}>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input form-control"
                    id="customFile"
                    onChange={uploadToCloudForProfileImage}
                  />
                </div>
              </td>
            </tr>
            {personalInfoFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default PersonalInfo;
