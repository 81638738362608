import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getItem } from "../../../utils/local-storage";
import Card from "../../../components/card";
import useMediaQuery from "../../../hooks/use-media-query";
import { actions as globalActions } from "../../../store/slice";
import { fetchProfile } from "../store/services";
import { actions } from "../store/slice";
import HoroscopePreview from "../view/horoscope-preview";
import BirthDetails from "./birth-details";
import ContactDetails from "./contact-details";
import Education from "./education";
import FamilyDetails from "./family-details";
import HoroscopeDetails from "./horoscope-details";
import NavamsaKatamDetails from "./navamsa-katam-details";
import OccupationDetails from "./occupation-details";
import PersonalInfo from "./personal-info";
import RasiKatamDetails from "./rasi-katam-details";
import ResidentialDetails from "./residential-details";
import UploadGovtId from "./upload-govt-id";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const ProfileEdit = () => {
  const dispatch = useDispatch();
  const components = [
    {
      name: "Upload Govt. ID",
      components: <UploadGovtId />,
    },
    {
      name: "Personal Info",
      components: <PersonalInfo />,
    },
    { name: "Birth Details", components: <BirthDetails /> },
    { name: "Family Details", components: <FamilyDetails /> },
    { name: "Education", components: <Education /> },
    { name: "Contact Details", components: <ContactDetails /> },
    { name: "Horoscope Details", components: <HoroscopeDetails /> },
    { name: "Rasi Katam Details", components: <RasiKatamDetails /> },
    { name: "Navamsa Katam Details", components: <NavamsaKatamDetails /> },
    { name: "Residential Details", components: <ResidentialDetails /> },
    { name: "Occupation Details", components: <OccupationDetails /> },
  ];
  const { isDesktop } = useMediaQuery();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    !getItem("the_main_app") && navigate("/login");
  });

  useEffect(() => {
    fetchProfile(getItem("the_main_app"))
      .then((response) => {
        const {
          birthDetails,
          createdBy,
          contactDetails,
          familyDetails,
          horoscopeDetails,
          horoscopeImage,
          govtId,
          lookingFor,
          navamsaKatam,
          navamsaPlanets,
          occupation,
          ownHouse,
          personalInfo,
          qualification,
          rasiKatam,
          rasiPlanets,
          residentialAddress,
          startDate,
          currentUser,
        } = response?.data?.values[0];

        dispatch(actions.setBirthDetails(birthDetails));
        dispatch(actions.setContactDetails(contactDetails));
        dispatch(actions.setFamilyDetails(familyDetails));
        dispatch(actions.setHoroscopeDetails(horoscopeDetails));
        dispatch(actions.setPersonalInfo(personalInfo));
        dispatch(actions.setOccupationDetails(occupation));
        dispatch(actions.setQualification(qualification));
        dispatch(actions.setRasiKatam(rasiKatam));
        dispatch(actions.setRasiPlanets(rasiPlanets));
        dispatch(actions.setNavamsaKatam(navamsaKatam));
        dispatch(actions.setNavamsaPlanets(navamsaPlanets));
        dispatch(actions.setResidentialAddress(residentialAddress));
        dispatch(
          actions.setProfileDetails({
            createdBy,
            horoscopeImage,
            govtId,
            lookingFor,
            ownHouse,
            startDate,
          })
        );
        dispatch(
          globalActions.setLoginSuccess({
            emailAddress: currentUser.emailAddress,
            username: currentUser.username,
            userId: currentUser.userId,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        paddingTop: "15px",
        marginTop: isDesktop ? "72px" : "96px",
      }}
      id="profile-edit-box"
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ padding: "0 25px", height: "975px", overflowX: "auto" }}
        >
          {components.map(({ name, components }, index) => (
            <Card key={index} title={name} content={components} id={index} />
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            padding: "15px 0",
            backgroundColor: "rgb(101, 110, 131)",
            display: "flex",
            justifyContent: "center",
            position: isDesktop ? "" : "relative",
            alignItems: "center",
          }}
        >
          <HoroscopePreview />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileEdit;
