import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../config/config";
import { getHoroscopeDetails } from "../store/selector";
import { actions } from "../store/slice";

const HoroscopeDetails = () => {
  const dispatch = useDispatch();
  const horoscopeDetails = useSelector(getHoroscopeDetails);
  const {
    rasi,
    nakshatra,
    gothram,
    mathulam,
    lagnam,
    naaligai,
    thithi,
    dasaPlanet,
    dasaBalance,
  } = horoscopeDetails;

  useEffect(() => {
    dispatch(
      actions.setHoroscopeDetails({
        rasi,
        nakshatra,
        gothram,
        lagnam,
        mathulam,
        naaligai,
        thithi,
        dasaPlanet,
        dasaBalance,
      })
    );
  }, [
    rasi,
    nakshatra,
    gothram,
    lagnam,
    mathulam,
    naaligai,
    thithi,
    dasaPlanet,
    dasaBalance,
  ]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td>Rasi</td>
              <td>
                <div className="form-group">
                  <select
                    className="form-control custom-select"
                    id="exampleFormControlSelect1-rasi"
                    name="rasi"
                    defaultValue={rasi}
                    onChange={(e) =>
                      dispatch(
                        actions.setHoroscopeDetails({
                          ...horoscopeDetails,
                          rasi: e.target.value,
                        })
                      )
                    }
                  >
                    {config.rasi.map((value, index) => {
                      return <option key={index}>{value.rasiValue}</option>;
                    })}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>Nakshatra</td>
              <td>
                <div className="form-group">
                  <select
                    className="form-control custom-select"
                    id="exampleFormControlSelect1-nakshatra"
                    name="nakshatra"
                    defaultValue={nakshatra}
                    onChange={(e) =>
                      dispatch(
                        actions.setHoroscopeDetails({
                          ...horoscopeDetails,
                          nakshatra: e.target.value,
                        })
                      )
                    }
                  >
                    {config.nakshatra.map((value, index) => {
                      return (
                        <option key={index}>{value.nakshatraValue}</option>
                      );
                    })}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>gothram</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="gothram"
                  aria-describedby="gothram"
                  placeholder="..."
                  name="gothram"
                  defaultValue={gothram}
                  onChange={(e) =>
                    dispatch(
                      actions.setHoroscopeDetails({
                        ...horoscopeDetails,
                        gothram: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Mathulam</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="mathulam"
                  aria-describedby="mathulam"
                  placeholder="..."
                  name="mathulam"
                  defaultValue={mathulam}
                  onChange={(e) =>
                    dispatch(
                      actions.setHoroscopeDetails({
                        ...horoscopeDetails,
                        mathulam: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Lagnam</td>
              <td>
                <div className="form-group">
                  <select
                    className="form-control custom-select"
                    id="lagnam"
                    aria-describedby="lagnam"
                    name="lagnam"
                    defaultValue={lagnam}
                    onChange={(e) =>
                      dispatch(
                        actions.setHoroscopeDetails({
                          ...horoscopeDetails,
                          lagnam: e.target.value,
                        })
                      )
                    }
                  >
                    {config.rasi.map((value, index) => {
                      return <option key={index}>{value.rasiValue}</option>;
                    })}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>Naaligai</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="naaligai"
                  aria-describedby="naaligai"
                  placeholder="..."
                  name="naaligai"
                  defaultValue={naaligai}
                  onChange={(e) =>
                    dispatch(
                      actions.setHoroscopeDetails({
                        ...horoscopeDetails,
                        naaligai: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Thithi</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="thithi"
                  aria-describedby="thithi"
                  placeholder="..."
                  name="thithi"
                  defaultValue={thithi}
                  onChange={(e) =>
                    dispatch(
                      actions.setHoroscopeDetails({
                        ...horoscopeDetails,
                        thithi: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Dasa Planet</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="dasaPlanet"
                  aria-describedby="dasaPlanet"
                  placeholder="..."
                  name="dasaPlanet"
                  defaultValue={dasaPlanet}
                  onChange={(e) =>
                    dispatch(
                      actions.setHoroscopeDetails({
                        ...horoscopeDetails,
                        dasaPlanet: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Dasa Balance</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="dasaBalance"
                  aria-describedby="dasaBalance"
                  placeholder="..."
                  name="dasaBalance"
                  defaultValue={dasaBalance}
                  onChange={(e) =>
                    dispatch(
                      actions.setHoroscopeDetails({
                        ...horoscopeDetails,
                        dasaBalance: e.target.value,
                      })
                    )
                  }
                />
                <small id="dasaBalance" className="form-text text-muted">
                  6 Years 10 Months 5 Days.
                </small>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default HoroscopeDetails;
