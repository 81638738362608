import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";

const PaginationComponent = ({
  pageSize,
  recordSize,
  onChange,
  pageNumber,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(recordSize / pageSize);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);

  return (
    <div>
      {totalPages > 1 ? (
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={onChange || handlePageChange}
          color="primary"
          variant="outlined"
          shape="rounded"
          showFirstButton
          showLastButton
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default PaginationComponent;
