export const USER_API = "/api/user/";
export const ACCOUNT_API = "/api/account/";

export const isDev = process.env.REACT_APP_ENV === "development";
export const isLocalHost = process.env.REACT_APP_ENV === "localhost";
export const isTesting = process.env.REACT_APP_ENV === "testing";
export const isProd = process.env.REACT_APP_ENV === "production";
export const isDevAdmin = process.env.REACT_APP_ENV === "devAdmin";
export const isQaAdmin = process.env.REACT_APP_ENV === "qaAdmin";
export const isProdAdmin = process.env.REACT_APP_ENV === "prodAdmin";

export const HOST = isDev
  ? process.env.REACT_APP_DEV_HOST
  : isTesting
  ? process.env.REACT_APP_QA_HOST
  : isProd
  ? process.env.REACT_APP_PROD_HOST
  : isDevAdmin
  ? process.env.REACT_APP_DEV_ADMIN_HOST
  : isQaAdmin
  ? process.env.REACT_APP_QA_ADMIN_HOST
  : process.env.REACT_APP_PROD_ADMINHOST;

export const SIGNUP = HOST + USER_API + "singup";
export const SIGNIN = HOST + ACCOUNT_API + "signin";
export const SIGNOUT = HOST + ACCOUNT_API + "signout?token=";
export const VERIFY = HOST + ACCOUNT_API + "verify?token=";
export const PROFILE_FETCH = HOST + ACCOUNT_API + "profile/fetch?token=";
export const SEARCH_FETCH = HOST + ACCOUNT_API + "profile/searchProfile?token=";
export const MEMBER_SEARCH_FETCH =
  HOST + ACCOUNT_API + "profile/searchMemberProfile?token=";
export const PROFILE_EDIT = HOST + ACCOUNT_API + "profile/profileEdit";
export const SUBSCRIBE = HOST + USER_API + "subscribe";
