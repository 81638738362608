import Box from "@mui/material/Box";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorToaster, successToaster } from "../../../utils/alerts";
import { getGovtId } from "../store/selector";
import { actions } from "../store/slice";

const UploadGovtId = () => {
  const govtId = useSelector(getGovtId);
  const dispatch = useDispatch();

  const uploadToCloudForGovtIdImage = async (e) => {
    const url = `${process.env.REACT_APP_CLOUD_URL}`;
    const preset = `${process.env.REACT_APP_CLOUD_PRESET}`;
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", preset);
    await axios({
      url: url,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: formData,
    })
      .then((res) => {
        successToaster("Govt Id Image Uploaded Successfully!!!");
        dispatch(actions.setGovtId(res.data.secure_url));
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: personal-info.js:59 ~ uploadToCloudForProfileImage ~ err:",
          err
        );
        errorToaster("Sorry,Error in Uploading image...Try again later", err);
      });
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={2} style={{ borderTop: "none" }}>
                <div
                  className="media"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={govtId || "../../assets/images/card_1.jpg"}
                    className="profile-img w mr-3"
                    alt="profile-pic"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ borderTop: "none" }}>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input form-control"
                    id="customFile"
                    onChange={uploadToCloudForGovtIdImage}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default UploadGovtId;
