import React from "react";
import { useNavigate } from "react-router-dom";
import { Links, officeContactDetails, socialLinks } from "../../config/config";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div
      className="lime-footer"
      style={{
        width: "100%",
        backgroundColor: "#CFD1E6",
        padding: "30px",
      }}
    >
      <section
        className="contact-details container pt-10 pb-10"
        style={{ padding: "30px 0" }}
        id="contact-details-section"
      >
        <div className="row text-center text-md-left ">
          <div className="col-12 col-md-6 col-lg-4 grid-margin">
            <img
              src="https://res.cloudinary.com/dc3dylylv/image/upload/v1670433144/Logos/Kalyanawaran/Finalized_Logo_Kalyawaran_-_1725_x_353_guysdv.png"
              alt=""
              width={175}
              className="pb-2"
            />
            <div className="pt-2">
              <a
                className="text-muted m-0"
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                href={`mailto:${officeContactDetails[0].emailId}`}
              >
                {officeContactDetails[0].emailId}
              </a>
              <br />
              <a
                className="text-muted m-0"
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                href={`tel:${officeContactDetails[0].phone}`}
              >
                {officeContactDetails[0].phone}
              </a>
            </div>
          </div>
          {/* <div className="col-12 col-md-6 col-lg-3 grid-margin">
            <h5 className="pb-2">Get in Touch</h5>
            <p className="text-muted">
              Don’t miss any updates of our new features and so on.!
            </p>
            <form>
              <input
                type="text"
                className="form-control"
                id="Email"
                placeholder="Email id"
                defaultValue={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </form>
            <div className="pt-3">
              <button
                className="btn btn-dark"
                onClick={() =>
                  subscribe(emailAddress)
                    .then((response) => {
                      console.log("response:", response);
                      setEmailAddress("");
                    })
                    .catch((err) => {
                      console.log(err);
                      setEmailAddress("");
                    })
                }
              >
                Subscribe
              </button>
            </div>
          </div> */}
          <div
            className="col-12 col-md-6 col-lg-4 grid-margin"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h5 className="pb-2">Our Guidelines</h5>
            <button
              className="btn btn-link btn-sm"
              onClick={() => navigate(Links.terms)}
              style={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <p className="m-0 pb-2">Terms</p>
            </button>
            <button
              className="btn btn-link btn-sm"
              onClick={() => navigate(Links.privacy)}
              style={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <p className="m-0 pt-1 pb-2">Privacy policy</p>
            </button>
            {/* <a
              href={Links.cookie}
              style={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <p className="m-0 pt-1 pb-2">Cookie Policy</p>
            </a>
            <a href="#">
              <p className="m-0 pt-1">Discover</p>
            </a> */}
          </div>
          <div className="col-12 col-md-6 col-lg-4 grid-margin">
            <h5 className="pb-2">Our address</h5>
            <p className="text-muted">
              {officeContactDetails[0].addressFirstLine}
              <br />
              {officeContactDetails[0].city} - {officeContactDetails[0].pinCode}
              .
            </p>
            {socialLinks.length > 0 && (
              <div className="d-flex justify-content-center justify-content-md-start">
                <a href={() => false}>
                  <span
                    className="fa-brands fa-square-facebook"
                    style={{ fontSize: "25px", marginRight: "10px" }}
                  />
                </a>
                <a href={() => false}>
                  <span
                    className="fa-brands fa-square-twitter"
                    style={{ fontSize: "25px", marginRight: "10px" }}
                  />
                </a>
                <a href={() => false}>
                  <span
                    className="fa-brands fa-square-instagram"
                    style={{ fontSize: "25px", marginRight: "10px" }}
                  />
                </a>
                <a href={() => false}>
                  <span
                    className="fa-brands fa-linkedin"
                    style={{ fontSize: "25px" }}
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
      <footer className="border-top">
        <p className="text-center text-muted pt-4">
          Copyright © 2020
          <a
            href="https://kalyanawaran.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="px-1"
          >
            Kalyanawaran.
          </a>
          All rights reserved.
        </p>
        <p className="text-center text-muted pb-4">
          Made with <i className="fa-solid fa-heart" style={{ color: "red" }} />{" "}
          by
          <a
            href="https://www.atdoor.co.in/"
            target="_blank"
            rel="noopener noreferrer"
            className="px-1"
          >
            AtDoor.
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Footer;
