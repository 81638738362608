import React from "react";

const TextInput = ({
  type = "text",
  name = "name",
  value = "",
  onChange,
  placeholder = "",
  className = "",
  onKeyDown,
}) => (
  <input
    type={type}
    className={"form-control " + className}
    id={name}
    aria-describedby={name}
    placeholder={placeholder}
    name={name}
    onChange={onChange}
    onKeyDown={onKeyDown}
    defaultValue={value}
  />
);

export default TextInput;
