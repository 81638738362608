import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItem, removeItem } from "../../utils/local-storage";
import { handleSaveProfile } from "../../helpers/save-profile";
import useMediaQuery from "../../hooks/use-media-query";
import { getUser } from "../../store/selector";
import { onSignOut } from "../../store/services";
import { actions } from "../../store/slice";
import { getProfile, getProfileImage } from "../../view/profile/store/selector";
import Sidebar from "../sidebar";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Header = () => {
  const dispatch = useDispatch();
  const profileImage = useSelector(getProfileImage);
  const { token } = useSelector(getUser);
  const profileObj = useSelector(getProfile);
  const currentUser = getItem("current_user");
  const { isDesktop } = useMediaQuery();

  useEffect(() => {
    dispatch(actions.setToken(getItem("the_main_app")));
    const url = window.location.href;
    url.includes("profile") && dispatch(actions.setEditProfile(true));
    url.includes("profileedit") && dispatch(actions.setEditProfile(false));
  }, [dispatch]);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="lime-header" style={{ backgroundColor: "#f7f8fa" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar
            style={{
              backgroundColor: "#f4f7fc",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon style={{ color: "#000000", opacity: "0.5" }} />
            </IconButton>
            <nav
              className="navbar navbar-expand-lg"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a className="navbar-brand" href="/" style={{ margin: 0 }}>
                <img
                  src="https://res.cloudinary.com/dc3dylylv/image/upload/v1670433144/Logos/Kalyanawaran/Finalized_Logo_Kalyawaran_-_1725_x_353_guysdv.png"
                  alt="kalyanawaran"
                  width={225}
                />
              </a>
              <div
                // className="collapse navbar-collapse"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: isDesktop ? "flex-end" : "center",
                }}
                id="navbarSupportedContent"
              >
                {/* <div className="form-inline my-2 my-lg-0 search">
                      <input
                        className="form-control mr-sm-2"
                        type="search"
                        placeholder="Search for Grooms, Brides..."
                        aria-label="Search"
                      />
                    </div> 
                */}
                <ul
                  className="navbar-nav ml-auto"
                  style={{ alignItems: "center" }}
                >
                  {/* <Localization /> */}
                  {token && (
                    <>
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          if (token) {
                            removeItem("the_main_app");
                            removeItem("current_user");
                            onSignOut(token)
                              .then((res) =>
                                console.log(
                                  "🚀 ~ singout ~ .then ~ response:",
                                  res
                                )
                              )
                              .catch((err) => console.error("err", err));
                            window.location.reload(false);
                          }
                        }}
                      >
                        Logout
                      </button>
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                          handleSaveProfile(token, {
                            ...profileObj,
                            ...currentUser,
                          })
                        }
                      >
                        Save
                      </button>
                    </>
                  )}
                  <div>
                    <IconButton
                      aria-haspopup="true"
                      color="inherit"
                      aria-controls="profile-menu"
                    >
                      {/* AccountIcon  */}
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          profileImage === ""
                            ? "https://picsum.photos/50"
                            : profileImage
                        }
                      />
                    </IconButton>
                  </div>
                </ul>
              </div>
            </nav>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Sidebar />
        </Drawer>
      </Box>
    </div>
  );
};

export default Header;
