import TextField from "@mui/material/TextField";
import React from "react";
import PhoneInput from "react-phone-input-2";
import Label from "../label";

const LableWithFormField = ({
  text = "Name",
  id = "name",
  defaultValue,
  value,
  placeholder,
  onChange,
  type = "text",
  array,
}) => {
  return (
    <tr>
      <td style={{ borderTop: "none" }}>
        <Label text={text} id={id} />
      </td>
      {type === "text" && (
        <td style={{ borderTop: "none" }}>
          <input
            type="text"
            className="form-control"
            id={id}
            aria-describedby={id}
            placeholder={placeholder}
            name={id}
            onChange={onChange}
            defaultValue={defaultValue}
          />
        </td>
      )}
      {type === "select" && (
        <td style={{ borderTop: "none" }}>
          <select
            className="form-control custom-select"
            id={id}
            name={id}
            value={value}
            onChange={onChange}
          >
            {array.map(({ value }, index) => (
              <option key={index}>{value}</option>
            ))}
          </select>
        </td>
      )}
      {type === "textField" && (
        <td style={{ borderTop: "none" }}>
          <TextField
            id={id}
            type={id}
            value={value}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={onChange}
          />
        </td>
      )}
      {type === "phone" && (
        <td style={{ borderTop: "none" }}>
          <PhoneInput country={"us"} value={defaultValue} onChange={onChange} />
        </td>
      )}
    </tr>
  );
};

export default LableWithFormField;
