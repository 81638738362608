import React from "react";

import { useSelector } from "react-redux";
import { getComingSoon, getIsHeaderFooter } from "./store/selector";
import ComingsoonPage from "./components/comingsoon-page";
import Footer from "./components/footer";
import Header from "./components/header";

const App = ({ children }) => {
  const isHeaderFooter = useSelector(getIsHeaderFooter);
  const comingSoon = useSelector(getComingSoon);

  return comingSoon ? (
    <ComingsoonPage />
  ) : (
    <>
      {isHeaderFooter && <Header hamburger={true} />}
      <main style={{ backgroundColor: "#f4f7fC" }}>{children}</main>
      {isHeaderFooter && <Footer />}
    </>
  );
};

export default App;
