const initialState = {
  comingSoon: false,
  counter: 0,
  language: "en",
  isHeaderFooter: true,
  error: {
    status: false,
    message: "",
    severity: "info",
  },
  currentUser: {
    emailAddress: "",
    password: "",
    token: "",
    username: "",
    userId: "",
    confirmEmailAddress: "",
    confirmPassword: "",
    isSaving: false,
    isAuthenticated: false,
    signUpError: "",
    signInError: "",
    signInEmail: "",
    signInPassword: "",
    signUpEmail: "",
    signUpPassword: "",
    editProfile: false,
    isTermsAccepted: false,
  },
  isLoading: true,
};

export default initialState;
