import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calcAge } from "../../../utils/age-calculator";
import LableWithFormField from "../../../components/label-with-formfield";
import * as config from "../../../config/config";
import { getLang } from "../../../store/selector";
import { getBirthDetails } from "../store/selector";
import { actions } from "../store/slice";

const BirthDetails = () => {
  const dispatch = useDispatch();
  const birthDetails = useSelector(getBirthDetails);
  const { dob, tamilYear, tamilMonth, tamilDate } = birthDetails;
  const { dateOfBirth, placeOfBirth, time, day } = dob;
  const lang = useSelector(getLang);

  const birthDetailsFields = [
    {
      type: "text",
      text: "Place of Birth",
      id: "placeOfBirth",
      defaultValue: placeOfBirth,
      placeholder: "Chennai",
      onChange: (e) =>
        dispatch(
          actions.setBirthDetails({
            ...birthDetails,
            dob: { ...dob, placeOfBirth: e.target.value },
          })
        ),
    },
    {
      type: "select",
      text: "Tamil Date",
      id: "tamilDate",
      defaultValue: tamilDate,
      onChange: (e) =>
        dispatch(
          actions.setBirthDetails({
            ...birthDetails,
            tamilDate: e.target.value,
          })
        ),
      array: config.days,
    },
    {
      type: "select",
      text: "Tamil Month",
      id: "tamilMonth",
      defaultValue: tamilMonth,
      onChange: (e) =>
        dispatch(
          actions.setBirthDetails({
            ...birthDetails,
            tamilMonth: e.target.value,
          })
        ),
      array: config.tamilMonth[lang],
    },
    {
      type: "select",
      text: "Tamil Year",
      id: "tamilYear",
      defaultValue: tamilYear,
      onChange: (e) =>
        dispatch(
          actions.setBirthDetails({
            ...birthDetails,
            tamilYear: e.target.value,
          })
        ),
      array: config.tamilYear[lang],
    },
    {
      type: "textField",
      text: "Date of Birth",
      id: "date",
      value: dateOfBirth,
      onChange: (e) => {
        dispatch(
          actions.setBirthDetails({
            ...birthDetails,
            dob: { ...dob, dateOfBirth: e.target.value },
          })
        );
      },
    },
    {
      type: "textField",
      text: "Birth Time",
      id: "time",
      value: time,
      onChange: (e) => {
        dispatch(
          actions.setBirthDetails({
            ...birthDetails,
            dob: { ...dob, time: e.target.value },
          })
        );
      },
    },
  ];

  useEffect(() => {
    const d = new Date(dateOfBirth);
    let day = d.getDay();

    dispatch(
      actions.setBirthDetails({
        ...birthDetails,
        dob: { ...dob, day: config.dayOrder[day], age: calcAge(dateOfBirth) },
      })
    );
  }, [dateOfBirth]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {birthDetailsFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
            <tr>
              <td style={{ borderTop: "none" }}>
                <label htmlFor="exampleFormControlSelect1-age">Age</label>
              </td>
              <td style={{ borderTop: "none" }}>{calcAge(dateOfBirth)}</td>
            </tr>
            <tr>
              <td style={{ borderTop: "none" }}>
                <label htmlFor="exampleFormControlSelect1-day">Day</label>
              </td>
              <td style={{ borderTop: "none", textTransform: "capitalize" }}>
                {day}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default BirthDetails;
