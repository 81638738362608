import { createSelector } from "reselect";
import { getItem } from "../../../utils/local-storage";

const currentUser = getItem("current_user");

const searchProfileState = (state) => state.searchProfile;

const getCounter = createSelector(
  searchProfileState,
  (searchProfile) => searchProfile.counter
);

const getProfiles = createSelector(searchProfileState, (searchProfile) =>
  searchProfile.profiles.filter((value) => value.userId !== currentUser.userId)
);

const getPagination = createSelector(
  searchProfileState,
  (searchProfile) => searchProfile.pagination
);

export { getCounter, getPagination, getProfiles };
