import React from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../store/selector";
import LandingPage from "../../view/home/landing-page";

const Home = () => {
  const { isLoading } = useSelector(getUser);
  if (isLoading) {
    return <p style={{ textAlign: "center" }}>Loading...!</p>;
  } else {
    return <LandingPage />;
  }
};

export default Home;
