import React, { useEffect } from "react";
import LayoutPage from "../layout-page/";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const ForgetPassword = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <LayoutPage
      content={
        <form>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email address</label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>

          <div className="  form-group">
            <button type="submit" className="btn btn-primary">
              Send my details
            </button>
            <a
              href="login"
              className="btn btn-secondary"
              style={{ marginLeft: 5 }}
            >
              Login
            </a>
          </div>
        </form>
      }
      title="Forget Password"
    />
  );
};

export default ForgetPassword;
