import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initial-state";

const { actions, reducer } = createSlice({
  name: "global",
  initialState,
  reducers: {
    setCounterIncrement(state) {
      state.counter += 1;
    },
    setCounterDecrement(state) {
      state.counter -= 1;
    },
    setLanguage(state, actions) {
      state.language = actions.payload;
    },
    setCurrentUser(state, actions) {
      state.currentUser = actions.payload;
    },
    setIsHeaderFooter(state, actions) {
      state.isHeaderFooter = actions.payload;
    },
    setRegisterUser(state, actions) {
      state.registerUser = actions.payload;
    },
    setToken(state, actions) {
      state.currentUser.token = actions.payload;
    },
    setEditProfile(state, actions) {
      state.currentUser.editProfile = actions.payload;
    },
    setLoginSuccess(state, actions) {
      state.currentUser.emailAddress = actions.payload.emailAddress;
      state.currentUser.username = actions.payload.username;
      state.currentUser.userId = actions.payload.userId;
    },
    setError(state, actions) {
      state.error.status = actions.payload.status;
      state.error.message = actions.payload.message;
      state.error.severity = actions.payload.severity;
    },
  },
});

export { actions };
export default reducer;
