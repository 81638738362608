import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../config/config";
import { getQualification } from "../store/selector";
import { actions } from "../store/slice";

const Education = () => {
  const dispatch = useDispatch();
  const qualification = useSelector(getQualification);
  const { fieldOfStudy, degree } = qualification;

  useEffect(() => {
    dispatch(actions.setQualification({ fieldOfStudy, degree }));
  }, [fieldOfStudy, degree, dispatch]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td style={{ borderTop: "none" }}>Field of Study</td>
              <td style={{ borderTop: "none" }}>
                <div className="form-group">
                  <select
                    className="form-control custom-select"
                    id="fieldOfStudy"
                    name="fieldOfStudy"
                    value={fieldOfStudy}
                    onChange={(e) =>
                      dispatch(
                        actions.setQualification({
                          ...qualification,
                          fieldOfStudy: e.target.value,
                        })
                      )
                    }
                  >
                    {config.fieldOfStudy.map((value, index) => {
                      return (
                        <option key={index}>{value.fieldOfStudyValue}</option>
                      );
                    })}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>Degree</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="degree"
                  aria-describedby="degree"
                  placeholder="M.Sc. / ..."
                  value={degree}
                  name="degree"
                  onChange={(e) =>
                    dispatch(
                      actions.setQualification({
                        ...qualification,
                        degree: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default Education;
