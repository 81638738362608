import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "../view/home/store/slice";
import memberReducer from "../view/members/store/slice";
import profileReducer from "../view/profile/store/slice";
import searchProfileReducer from "../view/search-profile/store/slice";
import globalReducer from "./slice";

export default configureStore({
  reducer: {
    global: globalReducer,
    home: homeReducer,
    profile: profileReducer,
    searchProfile: searchProfileReducer,
    member: memberReducer,
  },
});
