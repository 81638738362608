// export const calcAge = (date) => {
//   console.log("date", date);
//   let month, day, year, currDate, currMon, currDay, currYear;
//   day = date.getDate();
//   month = date.getMonth() + 1;
//   year = date.getFullYear();

//   currDate = new Date();
//   currDay = currDate.getDate();
//   currMon = currDate.getMonth() + 1;
//   currYear = currDate.getFullYear();
//   return `${currYear - year} years and ${currMon - month} months`;
// };

export const calcAge = (date) => {
  let month, day, year, currDate, currMon, currDay, currYear;
  var date = date.split("-");

  day = date[2];
  month = date[1];
  year = date[0];

  currDate = new Date();
  currDay = currDate.getDate();
  currMon = currDate.getMonth() + 1;
  currYear = currDate.getFullYear();

  return `${currYear - year} years`;
};
