import React from "react";
import LayoutPage from "../layout-page";

const Verification = () => (
  <LayoutPage
    content={
      <div>
        <h5 className="card-title">
          Enter Your One Time Password Received Through Email{" "}
        </h5>
        <form>
          <div className="form-group">
            <label for="exampleInputEmail1">Email OTP</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Email OTP"
            />
          </div>
          <div className="form-group">
            <label for="exampleInputEmail1">Mobile OTP</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Mobile OTP"
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Verify
          </button>
        </form>
      </div>
    }
    title="Verification"
  />
);

export default Verification;
