import axios from "axios";
import {
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SUBSCRIBE,
  VERIFY,
} from "../config/api-const";

export const onSignUp = async (userObj) =>
  await axios.post(SIGNUP, { userObj });

export const onSignIn = async ({ emailAddress, password }) =>
  await axios.post(SIGNIN, {
    emailAddress: emailAddress,
    password: password,
  });

export const onSignOut = async (token) =>
  token && (await axios.get(SIGNOUT + token));

export const verifyToken = async (token) =>
  token && (await axios.get(VERIFY + token));

export const subscribe = async (emailAddress) =>
  emailAddress && (await axios.post(SUBSCRIBE, { emailAddress: emailAddress }));
