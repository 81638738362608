import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/reducer";

// Component Imports
import Home from "./components/home";
import Register from "./components/register";
import Login from "./components/login";
import ProfileEdit from "./view/profile/edit";
import ForgetPassword from "./components/forget-password";
import Verify from "./components/verification";
import Counter from "./components/counter";
import ProfilesPage from "./view/search-profile";
import MembersPage from "./view/members/members-pages";
import Settings from "./components/settings";
import Account from "./components/account";
import NotFound from "./components/not-found";
import Terms from "./components/terms";
import PrivacyPolicy from "./components/privacy-policy/";
import CookiePolicy from "./components/cookie-policy";
import ReactGA from "react-ga";

const links = [
  { path: "/", component: <Home /> },
  { path: "/register", component: <Register /> },
  { path: "/login", component: <Login /> },
  { path: "/profileedit", component: <ProfileEdit /> },
  { path: "/forgetpassword", component: <ForgetPassword /> },
  { path: "/verify", component: <Verify /> },
  { path: "/counter", component: <Counter /> },
  { path: "/search-profiles", component: <ProfilesPage /> },
  { path: "/members/:id", component: <MembersPage /> },
  { path: "/settings", component: <Settings /> },
  { path: "/account", component: <Account /> },
  { path: "*", component: <NotFound /> },
  { path: "/terms", component: <Terms /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/cookie-policy", component: <CookiePolicy /> },
];

ReactGA.initialize(`${process.env.REACT_APP_GTAG}`);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <App>
          <Routes>
            {links.map(({ path, component }, index) => (
              <Route exact key={index} path={path} element={component} />
            ))}
          </Routes>
        </App>
      </Provider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
