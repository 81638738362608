import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../config/config";
import { getFamilyDetails } from "../store/selector";
import { actions } from "../store/slice";

const FamilyDetails = () => {
  const familyDetails = useSelector(getFamilyDetails);
  const dispatch = useDispatch();
  const {
    siblings,
    fatherName,
    fatherOccupation,
    fatherAlive,
    motherName,
    motherOccupation,
    motherAlive,
    religion,
    caste,
  } = familyDetails;
  const { brother, sister } = siblings;

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <Grid container spacing={2} mb={2}>
          <Grid
            item
            xs={2}
            style={{ alignItems: "center", display: "flex", fontWeight: 700 }}
          >
            Brother
          </Grid>
          <Grid item xs={5}>
            <div>Number</div>
            <div>
              <input
                type="text"
                className="form-control"
                id="brotherNum"
                aria-describedby="brotherNum"
                placeholder=""
                value={brother.number}
                name="brotherNum"
                onChange={(e) =>
                  dispatch(
                    actions.setFamilyDetails({
                      ...familyDetails,
                      siblings: {
                        brother: { ...brother, number: e.target.value },
                        sister: { ...sister },
                      },
                    })
                  )
                }
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div>Married</div>
            <div>
              <input
                type="text"
                className="form-control"
                id="brotherMarriageNum"
                aria-describedby="brotherMarriageNum"
                placeholder=""
                value={brother.marriageNum}
                name="brotherMarriageNum"
                onChange={(e) =>
                  dispatch(
                    actions.setFamilyDetails({
                      ...familyDetails,
                      siblings: {
                        brother: {
                          ...brother,
                          marriageNum: e.target.value,
                        },
                        sister: { ...sister },
                      },
                    })
                  )
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid
            item
            xs={2}
            style={{ alignItems: "center", display: "flex", fontWeight: 700 }}
          >
            Sister
          </Grid>
          <Grid item xs={5}>
            <div>Number</div>
            <div>
              <input
                type="text"
                className="form-control"
                id="sisterNum"
                aria-describedby="sisterNum"
                placeholder=""
                value={sister.number}
                name="sisterNum"
                onChange={(e) =>
                  dispatch(
                    actions.setFamilyDetails({
                      ...familyDetails,
                      siblings: {
                        brother: { ...brother },
                        sister: {
                          ...sister,
                          number: e.target.value,
                        },
                      },
                    })
                  )
                }
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div>Married</div>
            <div>
              <input
                type="text"
                className="form-control"
                id="sisterMarriedNum"
                aria-describedby="sisterMarriedNum"
                placeholder=""
                value={sister.marriageNum}
                name="sisterMarriedNum"
                onChange={(e) =>
                  dispatch(
                    actions.setFamilyDetails({
                      ...familyDetails,
                      siblings: {
                        brother: { ...brother },
                        sister: {
                          ...sister,
                          marriageNum: e.target.value,
                        },
                      },
                    })
                  )
                }
              />
            </div>
          </Grid>
        </Grid>
        <table className="table">
          <tbody>
            <tr>
              <td>Father's Name</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="fatherName"
                  aria-describedby="fatherName"
                  placeholder=""
                  value={fatherName}
                  name="fatherName"
                  onChange={(e) =>
                    dispatch(
                      actions.setFamilyDetails({
                        ...familyDetails,
                        fatherName: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Father Alive</td>
              <td>
                <select
                  className="form-control custom-select"
                  id="exampleFormControlSelect1-father_Alive"
                  name="fatherAlive"
                  value={fatherAlive}
                  onChange={(e) => {
                    dispatch(
                      actions.setFamilyDetails({
                        ...familyDetails,
                        fatherAlive: e.target.value,
                      })
                    );
                  }}
                >
                  {config.yesOrNo.map(({ value }, index) => {
                    return <option key={index}>{value}</option>;
                  })}
                </select>
              </td>
            </tr>
            {fatherAlive === "Yes" && (
              <tr>
                <td>Father's Occupation</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="fatherOccupation"
                    aria-describedby="fatherOccupation"
                    placeholder="..."
                    name="fatherOccupation"
                    value={fatherOccupation}
                    onChange={(e) =>
                      dispatch(
                        actions.setFamilyDetails({
                          ...familyDetails,
                          fatherOccupation: e.target.value,
                        })
                      )
                    }
                  />
                </td>
              </tr>
            )}
            <tr>
              <td>Mother's Name</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="motherName"
                  aria-describedby="motherName"
                  placeholder=""
                  value={motherName}
                  name="motherName"
                  onChange={(e) =>
                    dispatch(
                      actions.setFamilyDetails({
                        ...familyDetails,
                        motherName: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Mother Alive</td>
              <td>
                <select
                  className="form-control custom-select"
                  id="exampleFormControlSelect2"
                  name="motherAlive"
                  value={motherAlive}
                  onChange={(e) =>
                    dispatch(
                      actions.setFamilyDetails({
                        ...familyDetails,
                        motherAlive: e.target.value,
                      })
                    )
                  }
                >
                  {config.yesOrNo.map(({ value }, index) => {
                    return <option key={index}>{value}</option>;
                  })}
                </select>
              </td>
            </tr>
            {motherAlive === "Yes" && (
              <tr>
                <td>Mother's Occupation</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="motherOccupation"
                    aria-describedby="motherOccupation"
                    placeholder="..."
                    name="motherOccupation"
                    value={motherOccupation}
                    onChange={(e) =>
                      dispatch(
                        actions.setFamilyDetails({
                          ...familyDetails,
                          motherOccupation: e.target.value,
                        })
                      )
                    }
                  />
                </td>
              </tr>
            )}
            <tr>
              <td>Caste</td>
              <td>
                <div className="form-group">
                  <select
                    className="form-control custom-select"
                    id="exampleFormControlSelect1-caste"
                    name="caste"
                    value={caste}
                    onChange={(e) =>
                      dispatch(
                        actions.setFamilyDetails({
                          ...familyDetails,
                          caste: e.target.value,
                        })
                      )
                    }
                  >
                    {config.caste.map(({ casteName }, index) => {
                      return <option key={index}>{casteName}</option>;
                    })}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>Religion</td>
              <td>
                <div className="form-group">
                  <select
                    className="form-control custom-select"
                    id="exampleFormControlSelect1-religion"
                    name="religion"
                    value={religion}
                    onChange={(e) =>
                      dispatch(
                        actions.setFamilyDetails({
                          ...familyDetails,
                          religion: e.target.value,
                        })
                      )
                    }
                  >
                    {config.religion.map(({ religionValue }, index) => {
                      return <option key={index}>{religionValue}</option>;
                    })}
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default FamilyDetails;
