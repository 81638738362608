const initialState = {
  counter: 0,
  isLoading: true,
  pagination: {
    pageSize: 3,
    pageNumber: 1,
  },
  profiles: [],
};

export default initialState;
