import Box from "@mui/material/Box";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOccupation } from "../store/selector";
import { actions } from "../store/slice";

const OccupationDetails = () => {
  const dispatch = useDispatch();
  const occupation = useSelector(getOccupation);
  const { companyName, designation, annualIncome, cityOfWorking } = occupation;

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td style={{ borderTop: "none" }}>Company Name</td>
              <td style={{ borderTop: "none" }}>
                <input
                  type="text"
                  className="form-control"
                  id="companyName"
                  aria-describedby="companyName"
                  placeholder="XYZ Pvt Ltd"
                  name="companyName"
                  value={companyName}
                  onChange={(e) =>
                    dispatch(
                      actions.setOccupationDetails({
                        ...occupation,
                        companyName: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Designation</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="designation"
                  aria-describedby="designation"
                  placeholder="Manager"
                  name="designation"
                  value={designation}
                  onChange={(e) =>
                    dispatch(
                      actions.setOccupationDetails({
                        ...occupation,
                        designation: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Annual Income</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="annualIncome"
                  aria-describedby="annualIncome"
                  placeholder="7 LPA"
                  value={annualIncome}
                  name="annualIncome"
                  onChange={(e) =>
                    dispatch(
                      actions.setOccupationDetails({
                        ...occupation,
                        annualIncome: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>City of Work</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="cityOfWorking"
                  aria-describedby="cityOfWorking"
                  placeholder="Chennai"
                  name="cityOfWorking"
                  defaultValue={cityOfWorking}
                  onChange={(e) =>
                    dispatch(
                      actions.setOccupationDetails({
                        ...occupation,
                        cityOfWorking: e.target.value,
                      })
                    )
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default OccupationDetails;
