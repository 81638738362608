import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorToaster, successToaster } from "../../utils/alerts";
import { getItem } from "../../utils/local-storage";
import { getUser } from "../../store/selector";
import { onSignUp, verifyToken } from "../../store/services";
import { actions } from "../../store/slice";
import LayoutPage from "../layout-page";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const Register = () => {
  const location = useLocation();
  const currentUser = useSelector(getUser);
  const {
    username,
    emailAddress,
    confirmEmailAddress,
    password,
    confirmPassword,
    isTermsAccepted,
  } = currentUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const token = getItem("the_main_app");
    token &&
      verifyToken(token)
        .then((res) => {
          if (res.data.success) {
            dispatch(actions.setToken(token));
            dispatch(actions.setIsHeaderFooter(true));
            navigate("/");
          }
        })
        .catch((err) => console.error("err", err));
  });

  return (
    <LayoutPage
      content={
        <div>
          <div className="form-group pb-2">
            <input
              type="email"
              name="emailAddress"
              className="form-control"
              id="exampleInputEmail1"
              // aria-describedby="emailHelp"
              placeholder="Enter email"
              value={emailAddress}
              onChange={(e) =>
                dispatch(
                  actions.setCurrentUser({
                    ...currentUser,
                    emailAddress: e.target.value,
                  })
                )
              }
              required
            />
          </div>
          <div className="form-group pb-2">
            <input
              type="email"
              name="confirmEmailAddress"
              className="form-control"
              id="exampleInputEmail2"
              aria-describedby="emailHelp2"
              placeholder="Confirm email"
              value={confirmEmailAddress}
              onChange={(e) =>
                dispatch(
                  actions.setCurrentUser({
                    ...currentUser,
                    confirmEmailAddress: e.target.value,
                  })
                )
              }
              required
            />
          </div>
          <div className="form-group pb-2">
            <input
              type="text"
              name="username"
              className="form-control"
              id="userName"
              aria-describedby="userName"
              placeholder="Username"
              value={username}
              onChange={(e) =>
                dispatch(
                  actions.setCurrentUser({
                    ...currentUser,
                    username: e.target.value,
                  })
                )
              }
              required
            />
          </div>
          <div className="form-group pb-2">
            <input
              type="password"
              name="password"
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              value={password}
              onChange={(e) =>
                dispatch(
                  actions.setCurrentUser({
                    ...currentUser,
                    password: e.target.value,
                  })
                )
              }
              required
            />
            {password.length < 1 ? (
              <></>
            ) : password.length <= 4 ? (
              <p style={{ color: "red", marginTop: "10px" }}>Week</p>
            ) : password.length <= 8 ? (
              <p style={{ color: "orange", marginTop: "10px" }}>Medium</p>
            ) : (
              <p style={{ color: "green", marginTop: "10px" }}>Strong</p>
            )}
          </div>
          <div className="form-group pb-2">
            <input
              type="password"
              name="confirmPassword"
              className="form-control"
              id="exampleInputPassword2"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) =>
                dispatch(
                  actions.setCurrentUser({
                    ...currentUser,
                    confirmPassword: e.target.value,
                  })
                )
              }
              required
            />
          </div>
          {confirmPassword.length < 1 ? (
            <p></p>
          ) : password === confirmPassword ? (
            <p
              style={{
                color: "green",
                marginTop: "10px",
              }}
            >
              Password matching
            </p>
          ) : (
            <p style={{ color: "red", marginTop: "10px" }}>Password mismatch</p>
          )}
          <div
            className="custom-control custom-checkbox form-group pb-2"
            style={{ padding: 0 }}
          >
            <label>
              <input
                type="checkbox"
                defaultChecked={isTermsAccepted}
                onChange={() =>
                  dispatch(
                    actions.setCurrentUser({
                      ...currentUser,
                      isTermsAccepted: !isTermsAccepted,
                    })
                  )
                }
              />
              {"  "}I Agree with{" "}
              <button
                className="btn btn-link"
                onClick={() => navigate("/terms")}
              >
                Terms &amp; Conditions
              </button>
            </label>
          </div>

          <button
            //href="#"
            className="btn btn-primary"
            style={{ marginRight: 5 }}
            onClick={() =>
              onSignUp({
                emailAddress,
                password,
                username,
                isTermsAccepted,
              })
                .then((res) => {
                  dispatch(
                    actions.setCurrentUser({
                      username: "",
                      emailAddress: "",
                      confirmEmailAddress: "",
                      password: "",
                      confirmPassword: "",
                      isTermsAccepted: "",
                    })
                  );
                  return successToaster(res.data.message, 6000);
                })
                .catch((err) => {
                  dispatch(
                    actions.setCurrentUser({
                      username: "",
                      emailAddress: "",
                      confirmEmailAddress: "",
                      password: "",
                      confirmPassword: "",
                      isTermsAccepted: "",
                    })
                  );
                  return errorToaster(err.response.data.message, 6000);
                })
            }
          >
            Register
          </button>
          <button
            className="btn btn-secondary"
            style={{ marginLeft: 5 }}
            onClick={() => navigate("/login")}
          >
            Login
          </button>
        </div>
      }
      title="Sign Up"
    />
  );
};

export default Register;
