import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initial-state";

const { actions, reducer } = createSlice({
  name: "searchProfile",
  initialState,
  reducers: {
    setCounterIncrement(state) {
      state.counter += 1;
    },
    setCounterDecrement(state) {
      state.counter -= 1;
    },
    setAllProfiles(state, actions) {
      state.profiles = actions.payload;
    },
    setPageNumber(state, actions) {
      state.pagination.pageNumber = actions.payload;
    },
  },
});

export { actions };
export default reducer;
