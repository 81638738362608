import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../store/slice";

const LayoutPage = ({ content = <></>, title = "" }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setIsHeaderFooter(false));
  }, [dispatch]);

  return (
    <div className="login-page err-500">
      <div className="container">
        <div className="login-container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-9 lfh">
              <div className="card login-box">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <a href="/">
                      <img
                        src="https://res.cloudinary.com/dc3dylylv/image/upload/v1670433144/Logos/Kalyanawaran/Finalized_Logo_Kalyawaran_-_1725_x_353_guysdv.png"
                        alt="kalyanawaran"
                        width={225}
                      />
                    </a>
                    <br />
                    <h5 className="card-title">{title}</h5>
                  </div>

                  {content}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutPage;
